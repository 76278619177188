import React, { forwardRef } from "react";
import { NavLink as Link } from "react-router";

const RouterLink = forwardRef((props, ref) => (
  <div ref={ref}>
    <Link {...props} />
  </div>
));

export default RouterLink;
