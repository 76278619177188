import axios from "./axios";

const baseUrl = "/insurers";

export const getInsurers = () => axios.get(baseUrl);

export const postInsurer = (data) => axios.post(baseUrl, data);

export const updateInsurer = ({ id, ...data }) =>
  axios.put(`${baseUrl}/${id}`, data);
