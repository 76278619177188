import axios from "./axios";

const baseUrl = "/certificates";

export const getCertificates = (contactId) =>
  axios.get(`${baseUrl}/contact/${contactId}`);

export const getCertificate = (id) => axios.get(`${baseUrl}/${id}`);

export const getCertificateVerify = (id, key) =>
  axios.get(`${baseUrl}/${id}/verify?key=${key}`);

export const postCertificate = (data) => axios.post(baseUrl, data);

export const updateCertificate = ({ id, ...data }) =>
  axios.put(`${baseUrl}/${id}`, data);

export const getCertificateCustomize = ({ id, ...data }) =>
  axios.post(`${baseUrl}/customize/${id}`, data);

export const getCertificatePdf = (id) =>
  axios.get(`${baseUrl}/pdf/${id}`, {
    responseType: "blob", // Ensures the response is treated as a Blob
  });

export const emailCertificate = ({ certificateId, ...data }) =>
  axios.post(`${baseUrl}/email/${certificateId}`, data);
