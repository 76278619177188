import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
  postInsurer,
  updateInsurer,
  selectInsurerById,
} from "features/insurers/insurersSlice";
import { useParams, useNavigate } from "react-router";
import * as Yup from "yup";
import { useInsurers } from "hooks";
import { Spinner } from "components";
import { Button } from "@mui/material";

const InsurerSchema = Yup.object().shape({
  name: Yup.string().required("Insurer name is required"),
});

const InsurerManage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { status, error } = useInsurers();
  const { id } = useParams();
  const insurer = useSelector((state) => selectInsurerById(state, id));

  if (status === "loading") return <Spinner />;
  if (status === "failed")
    return <div className="text-red-600">Error: {error}</div>;

  return (
    <>
      <h2 className="text-4xl font-bold mb-6">
        {id ? "Update" : "Add"} Insurer
      </h2>
      <Formik
        initialValues={{ name: insurer?.name || "" }}
        validationSchema={InsurerSchema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          const dispatchMethod = id ? updateInsurer : postInsurer;
          dispatch(dispatchMethod({ id, ...values }))
            .unwrap()
            .then(() => navigate("/dashboard/insurers"))
            .catch((err) => console.error(err))
            .finally(() => setSubmitting(false));
        }}
      >
        {({ isSubmitting, touched, errors }) => (
          <Form className="max-w-screen-md">
            <div className="mb-5">
              <label htmlFor="name" className="label-class label-required">
                Name
              </label>
              <Field
                type="text"
                name="name"
                className={`field-base field-focus ${
                  touched.name && errors.name ? "field-error" : ""
                }`}
              />
              <ErrorMessage
                name="name"
                component="div"
                className="error-text"
              />
            </div>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={
                isSubmitting ? "btn btn-primary loading" : "btn btn-primary"
              }
              disabled={isSubmitting}
            >
              {isSubmitting ? "Saving" : "Save"}
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default InsurerManage;
