import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createBrowserRouter, RouterProvider, Outlet } from "react-router";
import {
  Certificate,
  Certificates,
  CertificateManage,
  CertificateCustomize,
  CertificateEmail,
  Contacts,
  ContactManage,
  Insurers,
  InsurerManage,
  Login,
  Logout,
  Unauthorized,
  ErrorPage,
} from "./views";
import { Dashboard, Root } from "./layouts";
import store from "./features/store";
import { Provider as StoreProvider } from "react-redux";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      { path: "/login", element: <Login />, errorElement: <ErrorPage /> },
      { path: "/logout", element: <Logout />, errorElement: <ErrorPage /> },
      {
        path: "/certificate/:certificateId/customize",
        element: <CertificateCustomize />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/unauthorized",
        element: <Unauthorized />,
        errorElement: <ErrorPage />,
      },
      {
        path: "dashboard",
        element: <Dashboard />,
        errorElement: <ErrorPage />,
        children: [
          { index: true, element: <Contacts />, errorElement: <ErrorPage /> },
          {
            path: "contacts",
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <Contacts />,
                errorElement: <ErrorPage />,
              },
              {
                path: ":contactId",
                element: <Outlet />,
                children: [
                  {
                    path: "update",
                    element: <ContactManage />,
                    errorElement: <ErrorPage />,
                  },
                  {
                    path: "certificates",
                    element: <Outlet />,
                    children: [
                      {
                        index: true,
                        element: <Certificates />,
                        errorElement: <ErrorPage />,
                      },
                      {
                        path: "add",
                        element: <CertificateManage />,
                        errorElement: <ErrorPage />,
                      },
                      {
                        path: ":certificateId",
                        element: <Outlet />,
                        errorElement: <ErrorPage />,
                        children: [
                          {
                            index: true,
                            element: <Certificate />,
                            errorElement: <ErrorPage />,
                          },
                          {
                            path: "manage",
                            element: <CertificateManage />,
                            errorElement: <ErrorPage />,
                          },
                          {
                            path: "email",
                            element: <CertificateEmail />,
                            errorElement: <ErrorPage />,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                path: "add",
                element: <ContactManage />,
                errorElement: <ErrorPage />,
              },
            ],
          },
          {
            path: "insurers",
            element: <Outlet />,
            errorElement: <ErrorPage />,
            children: [
              {
                index: true,
                element: <Insurers />,
                errorElement: <ErrorPage />,
              },
              {
                path: ":id/update",
                element: <InsurerManage />,
                errorElement: <ErrorPage />,
              },
              {
                path: "add",
                element: <InsurerManage />,
                errorElement: <ErrorPage />,
              },
            ],
          },
        ],
      },
    ],
  },
]);

// I need to setup axiosInterceptop here; at the top leve';
// for that i need the navigate() and token right here;
// its not possible to have navigate outside of router; we must encapsulate the whole app inside a root component to get the axios interceptors work;

const App = () => (
  <StoreProvider store={store}>
    <ThemeProvider theme={theme}>
      <ToastContainer />
      <RouterProvider router={router} />
    </ThemeProvider>
  </StoreProvider>
);

export default App;
