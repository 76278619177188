import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getContacts } from "features/contacts/contactsSlice";

const useContacts = () => {
  const dispatch = useDispatch();
  const { status, error } = useSelector((state) => state.contacts);

  useEffect(() => {
    if (status === "idle") {
      dispatch(getContacts());
    }
  }, [dispatch, status]);

  return {
    status,
    error,
  };
};

export default useContacts;
