import React, { useEffect } from "react";
import { Spinner } from "components";
import { useSearchParams, Link } from "react-router";
import { useContacts } from "hooks";
import { useSelector } from "react-redux";
import { selectAllContacts } from "features/contacts/contactsSlice";
import { Button } from "@mui/material";

const Contacts = () => {
  const { status, error } = useContacts();
  const contacts = useSelector(selectAllContacts);

  const [searchParams] = useSearchParams();
  const search = searchParams.get("search");

  useEffect(() => {}, []);

  if (status === "loading") return <Spinner />;
  if (status === "failed")
    return <div className="text-red-600">Error: {error}</div>;

  let filteredContacts = {};
  if (search) {
    const searchLowerCased = search.toLowerCase();

    filteredContacts = contacts.filter((contact) =>
      Object.values(contact).some((value) =>
        value.toString().toLowerCase().includes(searchLowerCased)
      )
    );
  } else {
    filteredContacts = contacts;
  }

  return (
    <>
      <div className="flex justify-between">
        <div>
          <h1 className="text-4xl font-bold">Contacts</h1>
          {filteredContacts.length && (
            <p className="my-4 text-gray-600">
              {search && (
                <>
                  Search for <span className="font-bold">{search}</span>
                </>
              )}{" "}
              found <span className="font-bold">{filteredContacts.length}</span>{" "}
              contacts
            </p>
          )}
        </div>
        {/* left */}
        <div className="text-right">
          <Button
            component={Link}
            to="./add"
            variant="contained"
            color="primary"
          >
            Add Contact
          </Button>
          <p className="text-sm text-gray-600 mt-2">
            Add Insured and Certificate Holders as contacts.
          </p>
        </div>
      </div>
      <ul className="border-t">
        {filteredContacts.map(
          ({
            _id,
            name,
            address,
            city,
            province,
            postalCode,
            phone,
            email,
          }) => (
            <li className="border-b" key={_id}>
              <Link
                to={`/dashboard/contacts/${_id}/certificates`}
                className="px-2 py-3 block px-2 py-3 hover:bg-amber-50"
              >
                <h5 className="font-medium text-blue-600 hover:text-black">
                  {name}
                </h5>
                <p className="mb-2">
                  {address}, {city}
                  {province} {postalCode}
                </p>{" "}
                <ul className="mb-0 flex">
                  {phone && (
                    <li className="mr-4">
                      <div className="text-xs text-gray-600 uppercase">
                        Phone
                      </div>{" "}
                      {phone}
                    </li>
                  )}
                  {email && (
                    <li>
                      <div className="text-xs text-gray-600 uppercase">
                        Email
                      </div>{" "}
                      {email}
                    </li>
                  )}
                </ul>
              </Link>
            </li>
          )
        )}
      </ul>
    </>
  );
};

export default Contacts;
