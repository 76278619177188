import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { emailCertificate } from "api/certificatesApi";
import { Link, useParams } from "react-router";
import { Autocomplete, Button, IconButton, TextField } from "@mui/material";
import { useContacts } from "hooks";
import { Spinner } from "../../components";
import { useSelector } from "react-redux";
import { selectAllContacts } from "../../features/contacts/contactsSlice";
import { toast } from "react-toastify";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const emailSchema = Yup.object().shape({
  emails: Yup.array()
    .min(1, "At least one email must be selected")
    .required("Email field is required"),
  subject: Yup.string().optional(),
  message: Yup.string().optional(),
});

const CertificateEmail = () => {
  const { certificateId, contactId } = useParams();
  const [emailStatus, setEmailStatus] = useState(null);
  const { status: statusContact, error: errorContacts } = useContacts();
  const contactsAll = useSelector(selectAllContacts);
  const [contacts, setContacts] = useState([]);
  const defaultValues = {
    emails: [],
    subject: `Your Scottroad Insurance Cargo Certificate - ${certificateId}`,
    message: `Hi,
Please find your certificate attached with this email.
Should you have any questions, please contact us.
`,
  };
  const [initialValues, setInitialValues] = useState(defaultValues);
  // Function to remove duplicate emails
  const getUniqueContacts = (contacts) => {
    const uniqueEmails = new Set();
    return contacts.filter((contact) => {
      if (!contact.email) {
        return false;
      }
      if (uniqueEmails.has(contact.email)) {
        return false;
      }
      uniqueEmails.add(contact.email);
      return true;
    });
  };

  useEffect(() => {
    if (statusContact === "succeeded") {
      setContacts(
        getUniqueContacts(contactsAll).sort((a, b) =>
          a.email.localeCompare(b.email)
        )
      );

      // update somethings in the email.
      setInitialValues({
        ...defaultValues,
        emails:
          contacts.length > 0
            ? contacts.filter((contact) => contact._id === contactId)
            : [],
      });
    }
  }, [statusContact, contacts.length]);

  if (statusContact === "loading") return <Spinner />;
  if (statusContact === "failed")
    return <div className="text-red-600">Error: {errorContacts}</div>;

  return (
    <>
      {contacts && contacts.length && (
        <>
          <div className="flex mb-8 items-center">
            <IconButton component={Link} to={"../"}>
              <ArrowBackIcon />
            </IconButton>
            <div className="ms-3">
              <h4 className="text-4xl font-bold">Email Certificate</h4>
              <p className="text-gray-600">CertID: {certificateId}</p>
            </div>
          </div>

          {(() => {
            if (emailStatus) {
              return (
                <div className="alert alert-success mb-3">
                  Status:: {emailStatus}
                </div>
              );
            }
          })()}
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={emailSchema}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);
              emailCertificate({
                ...values,
                certificateId,
                emails: values.emails.map((contact) => contact.email),
              })
                .then((response) => {
                  toast.success(`Email sent: ${response.message || ""}`);
                })
                .catch((err) => {
                  console.log(err);
                  //toast.error(`Error: ${err.message || "failed to email"}`);
                })
                .finally(() => {
                  setSubmitting(false);
                });
            }}
          >
            {({ isSubmitting, values, setFieldValue, errors, touched }) => (
              <Form autoComplete="off">
                <div className="mb-5">
                  <Autocomplete
                    multiple
                    options={contacts || []}
                    getOptionLabel={(option) => option.email || ""}
                    isOptionEqualToValue={(option, value) =>
                      option._id === value._id
                    }
                    value={values.emails}
                    onChange={(event, value) => setFieldValue("emails", value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Emails"
                        error={touched.emails && Boolean(errors.emails)}
                        helperText={touched.emails && errors.emails}
                      />
                    )}
                    renderOption={(props, option) => (
                      <li {...props} key={option._id}>
                        {option.email}
                      </li>
                    )}
                  />
                </div>

                <div className="mb-4">
                  <label htmlFor="subject" className="label-class">
                    Subject
                  </label>
                  <Field
                    type="text"
                    name="subject"
                    className={`field-base field-focus ${
                      touched.subject && errors.subject ? "field-error" : ""
                    }`}
                  />
                  <ErrorMessage
                    name="subject"
                    component="div"
                    className="error-text"
                  />
                </div>

                <div className="mb-4">
                  <label htmlFor="message" className="label-class">
                    Message
                  </label>
                  <Field
                    component="textarea"
                    rows="5"
                    name="message"
                    className={`field-base field-focus ${
                      touched.message && errors.message ? "field-error" : ""
                    }`}
                  />
                  <ErrorMessage
                    name="message"
                    component="div"
                    className="error-text"
                  />
                  <p className="text-blue-600 mt-3">
                    In case the certificate is customizable, its link will be
                    automatically added to the above email.
                  </p>
                </div>

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={
                    isSubmitting ? "btn btn-primary loading" : "btn btn-primary"
                  }
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Sending" : "Send"}
                </Button>
              </Form>
            )}
          </Formik>
        </>
      )}
    </>
  );
};

export default CertificateEmail;
