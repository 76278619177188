import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
  postContact,
  updateContact,
  selectContactById,
} from "features/contacts/contactsSlice";
import { useParams, useNavigate } from "react-router";
import { useContacts } from "hooks";
import { Spinner } from "components";
import { Button } from "@mui/material";
import * as Yup from "yup";

const PlansSchema = Yup.object().shape({
  name: Yup.string().required("Contact name is required"),
  address: Yup.string(),
  city: Yup.string(),
  province: Yup.string(),
  postalCode: Yup.string()
    .min(5, "Postal Code should be minimum 5 characters long")
    .max(8, "Postal Code should not be larger than 8 characters"),
  phone: Yup.string().length(10, "Phone number should be 10 digits long"),
  email: Yup.string().email("Please enter a valid email address"),
});

const ContactManage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { status, error } = useContacts();
  const { contactId: id } = useParams();
  const contact = useSelector((state) => selectContactById(state, id));

  if (status === "loading") return <Spinner />;
  if (status === "failed")
    return <div className="text-red-600">Error: {error}</div>;

  return (
    <>
      <h2 className="text-4xl font-bold mb-6">
        {id ? "Update" : "Add"} Contact
      </h2>
      <Formik
        initialValues={contact}
        validationSchema={PlansSchema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          const dispatchMethod = id ? updateContact : postContact;
          dispatch(dispatchMethod({ id, ...values }))
            .unwrap()
            .then(() => navigate("/dashboard/contacts"))
            .catch((err) => console.error(err))
            .finally(() => setSubmitting(false));
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <Form className="max-w-screen-md">
            <div className="mb-5">
              <label htmlFor="name" className="label-class label-required">
                Name
              </label>
              <Field
                type="text"
                name="name"
                className={`field-base field-focus ${
                  touched.name && errors.name ? "field-error" : ""
                }`}
              />
              <ErrorMessage
                name="name"
                component="div"
                className="error-text"
              />
            </div>
            {/* name */}

            <div className="mb-5">
              <label htmlFor="address" className="label-class">
                Address
              </label>
              <Field
                type="text"
                name="address"
                className={`field-base field-focus ${
                  touched.name && errors.name ? "field-error" : ""
                }`}
              />
              <ErrorMessage
                name="address"
                component="div"
                className="error-text"
              />
            </div>
            {/* address */}
            <div className="md:flex gap-4 mb-4">
              <div className="w-5/12">
                <div className="mb-5">
                  <label htmlFor="city" className="label-class">
                    City
                  </label>
                  <Field
                    type="text"
                    name="city"
                    className={`field-base field-focus ${
                      touched.name && errors.name ? "field-error" : ""
                    }`}
                  />
                  <ErrorMessage
                    name="city"
                    component="div"
                    className="error-text"
                  />
                </div>
              </div>
              <div className="w-5/12">
                <div className="mb-5">
                  <label htmlFor="province" className="label-class">
                    Province
                  </label>
                  <Field
                    name="province"
                    component="select"
                    className={`field-base field-focus ${
                      touched.name && errors.name ? "field-error" : ""
                    }`}
                  >
                    <option value=""></option>
                    <option value="AB">Alberta</option>
                    <option value="BC">British Columbia</option>
                    <option value="MB">Manitoba</option>
                    <option value="NB">New Brunswick</option>
                    <option value="NL">Newfoundland and Labrador</option>
                    <option value="NT">Northwest Territories</option>
                    <option value="NS">Nova Scotia</option>
                    <option value="NU">Nunavut</option>
                    <option value="ON">Ontario</option>
                    <option value="PE">Prince Edward Island</option>
                    <option value="QC">Quebec</option>
                    <option value="SK">Saskatchewan</option>
                    <option value="YT">Yukon</option>
                    <option value="AL">Alabama</option>
                    <option value="AK">Alaska</option>
                    <option value="AZ">Arizona</option>
                    <option value="AR">Arkansas</option>
                    <option value="AA">Armed Forces America</option>
                    <option value="AE">Armed Forces Europe</option>
                    <option value="AP">Armed Forces Pacific</option>
                    <option value="CA">California</option>
                    <option value="CO">Colorado</option>
                    <option value="CT">Connecticut</option>
                    <option value="DE">Delaware</option>
                    <option value="DC">District of Columbia</option>
                    <option value="FL">Florida</option>
                    <option value="GA">Georgia</option>
                    <option value="HI">Hawaii</option>
                    <option value="ID">Idaho</option>
                    <option value="IL">Illinois</option>
                    <option value="IN">Indiana</option>
                    <option value="IA">Iowa</option>
                    <option value="KS">Kansas</option>
                    <option value="KY">Kentucky</option>
                    <option value="LA">Louisiana</option>
                    <option value="ME">Maine</option>
                    <option value="MD">Maryland</option>
                    <option value="MA">Massachusetts</option>
                    <option value="MI">Michigan</option>
                    <option value="MN">Minnesota</option>
                    <option value="MS">Mississippi</option>
                    <option value="MO">Missouri</option>
                    <option value="MT">Montana</option>
                    <option value="NE">Nebraska</option>
                    <option value="NV">Nevada</option>
                    <option value="NH">New Hampshire</option>
                    <option value="NJ">New Jersey</option>
                    <option value="NM">New Mexico</option>
                    <option value="NY">New York</option>
                    <option value="NC">North Carolina</option>
                    <option value="ND">North Dakota</option>
                    <option value="OH">Ohio</option>
                    <option value="OK">Oklahoma</option>
                    <option value="OR">Oregon</option>
                    <option value="PA">Pennsylvania</option>
                    <option value="RI">Rhode Island</option>
                    <option value="SC">South Carolina</option>
                    <option value="SD">South Dakota</option>
                    <option value="TN">Tennessee</option>
                    <option value="TX">Texas</option>
                    <option value="UT">Utah</option>
                    <option value="VT">Vermont</option>
                    <option value="VA">Virginia</option>
                    <option value="WA">Washington</option>
                    <option value="WV">West Virginia</option>
                    <option value="WI">Wisconsin</option>
                    <option value="WY">Wyoming</option>
                  </Field>
                  <ErrorMessage
                    name="province"
                    component="div"
                    className="error-text"
                  />
                </div>
              </div>
              <div className="w-2/12">
                <div className="mb-5">
                  <label htmlFor="postalCode" className="label-class">
                    Postal Code
                  </label>
                  <Field
                    type="text"
                    name="postalCode"
                    className={`field-base field-focus ${
                      touched.name && errors.name ? "field-error" : ""
                    }`}
                  />
                  <ErrorMessage
                    name="postalCode"
                    component="div"
                    className="error-text"
                  />
                </div>
              </div>
            </div>
            {/* city/province/postalCode */}

            <div className="md:flex gap-2">
              <div className="w-1/2">
                <div className="mb-5">
                  <label htmlFor="phone" className="label-class">
                    Phone
                  </label>
                  <Field
                    type="text"
                    name="phone"
                    className={`field-base field-focus ${
                      touched.name && errors.name ? "field-error" : ""
                    }`}
                  />
                  <ErrorMessage
                    name="phone"
                    component="div"
                    className="error-text"
                  />
                </div>
              </div>
              <div className="w-1/2">
                <div className="mb-5">
                  <label htmlFor="email" className="label-class">
                    Email
                  </label>
                  <Field
                    type="text"
                    name="email"
                    className={`field-base field-focus ${
                      touched.name && errors.name ? "field-error" : ""
                    }`}
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="error-text"
                  />
                </div>
              </div>
            </div>
            {/* email/phone */}

            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={
                isSubmitting ? "btn btn-primary loading" : "btn btn-primary"
              }
              disabled={isSubmitting}
            >
              {isSubmitting ? "Saving" : "Save"}
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ContactManage;
