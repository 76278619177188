import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import * as contactApi from "api/contactsApi";

const contactsAdaptor = createEntityAdapter({
  selectId: (contact) => contact._id,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

export const getContacts = createAsyncThunk(
  "contacts/getContacts",
  async () => await contactApi.getContacts()
);

export const postContact = createAsyncThunk(
  "contacts/postContact",
  async (data) => await contactApi.postContact(data)
);

export const updateContact = createAsyncThunk(
  "contacts/updateContact",
  async (data) => await contactApi.updateContact(data)
);

const initialState = contactsAdaptor.getInitialState({
  status: "idle",
  error: null,
});

const contactsSlice = createSlice({
  name: "contacts",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getContacts.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getContacts.fulfilled, (state, action) => {
        if (action.payload) {
          state.status = "succeeded";
          contactsAdaptor.setAll(state, action.payload);
        }
      })
      .addCase(getContacts.rejected, (state, action) => {
        state.status = "failed";
        state.error = action?.error?.message;
      })
      .addCase(postContact.fulfilled, (state, action) => {
        state.status = "succeeded";
        contactsAdaptor.addOne(state, action.payload);
      })
      .addCase(updateContact.fulfilled, (state, action) => {
        const { _id, ...changes } = action.payload;
        state.status = "succeeded";
        contactsAdaptor.updateOne(state, {
          id: _id,
          changes,
        });
      });
  },
});

export default contactsSlice.reducer;

// Export default selectors
export const { selectAll: selectAllContacts, selectById: selectContactById } =
  contactsAdaptor.getSelectors((state) => state.contacts);
