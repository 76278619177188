import React, { useEffect } from "react";
import { Auth } from "../../layouts";
import { RouterLink } from "../../components";
import { Button } from "@mui/material";

const Unauthorized = () => {
  useEffect(() => {
    window.localStorage.removeItem("user");
  }, []);
  return (
    <Auth>
      <div className="text-center">
        <h1 className="font-bold text-4xl">Access Denied</h1>
        <p className="mb-6">
          The requested resource is protected. You must login to continue.
        </p>
        <Button
          color="primary"
          component={RouterLink}
          disableElevation
          to="/login"
          variant="contained"
        >
          Log In
        </Button>
      </div>
    </Auth>
  );
};

export default Unauthorized;
