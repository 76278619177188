import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router";
import { toast } from "react-toastify";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Datetime from "react-datetime";
import moment from "moment";
import {
  getCertificateCustomize,
  getCertificateVerify,
} from "../../api/certificatesApi";
import { Button } from "@mui/material";
import * as Yup from "yup";

const customizeCertificateSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  createdOn: Yup.date().required("Issue Date is required"),
  email: Yup.string()
    .email("Please enter a valid email address")
    .required("email is required"),
});

const CertificateCustomize = () => {
  const { certificateId } = useParams();
  const [searchParams] = useSearchParams();
  const holderDefaults = {
    name: "",
    address: "",
    city: "",
    province: "",
    postalCode: "",
  };
  const [holder, setHolder] = useState(holderDefaults);
  const [createdOn, setCreatedOn] = useState("");

  useEffect(() => {
    if (searchParams.get("key")) {
      getCertificateVerify(certificateId, searchParams.get("key"))
        .then((res) => {
          setHolder(res.holder ? res.holder : holderDefaults);
          setCreatedOn(res.createdOn ? res.createdOn : new Date());
        })
        .catch((err) => {
          console.log(err);
          toast.error(err.message);
        });
    } else {
      toast.error("key is required");
    }
  }, [searchParams.get("key")]);

  return (
    <div className="max-w-screen-sm mx-auto py-4">
      <h1 className="text-4xl font-bold mb-6">Customize Certificate</h1>
      <Formik
        enableReinitialize
        initialValues={{
          createdOn: createdOn,
          email: "",
          key: searchParams.get("key"),
          name: holder.name || "",
          address: holder.address || "",
          city: holder.city || "",
          province: holder.province || "",
          postalCode: holder.postalCode || "",
        }}
        validationSchema={customizeCertificateSchema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);

          getCertificateCustomize({
            id: certificateId,
            ...values,
          })
            .then(() => {
              toast.success(
                `certificate successfully sent to ${values?.email}`
              );
            })
            .catch((err) => {
              console.log(err);
              toast.error(err.message);
            })
            .finally(() => setSubmitting(false));
        }}
      >
        {({ values, errors, touched, setFieldValue, isSubmitting }) => (
          <Form>
            <Field
              type="hidden"
              name="key"
              className="form-control"
              value={values.key}
            />
            <div className="form-row justify-content-center">
              <div className="col-sm-6 col-md-5 col-lg-4">
                <p className="my-4 text-sm text-gray-600">
                  Field marked with * are required.
                </p>
                <div className="mb-4 max-w-40">
                  <label htmlFor="name" className="label-class label-required">
                    Issue Date
                  </label>
                  <Datetime
                    inputProps={{
                      name: "createdOn",
                      id: "createdOn",
                      autoComplete: "off",
                      placeholder: "MM/DD/YYYY",
                    }}
                    value={
                      values.createdOn &&
                      moment(values.createdOn).format("MM/DD/YYYY")
                    }
                    dateFormat="MM/DD/YYYY"
                    timeFormat={false}
                    closeOnSelect={true}
                    onChange={(time) => {
                      setFieldValue("createdOn", time ? time : null);
                    }}
                    className={`field-base field-focus ${
                      touched.createdOn && errors.createdOn ? "field-error" : ""
                    }`}
                  />
                  <ErrorMessage
                    name="createdOn"
                    component="div"
                    className="error-text"
                  />
                </div>

                <h2 className="font-bold mt-6 mb-4">Certificate Holder</h2>

                <div className="mb-4 max-w-80">
                  <label htmlFor="name" className="label-class label-required">
                    Name
                  </label>
                  <Field
                    name="name"
                    value={values.name}
                    className={`field-base field-focus ${
                      touched.name && errors.name ? "field-error" : ""
                    }`}
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="error-text"
                  />
                </div>
                {/* name */}

                <div className="mb-4">
                  <label htmlFor="address" className="label-class">
                    Address
                  </label>
                  <Field
                    name="address"
                    value={values.address}
                    className={`field-base field-focus ${
                      touched.address && errors.address ? "field-error" : ""
                    }`}
                  />
                  <ErrorMessage
                    name="address"
                    component="div"
                    className="error-text"
                  />
                </div>
                {/* address */}

                <div className="flex gap-2">
                  <div className="w-5/12">
                    <label htmlFor="city" className="label-class">
                      City
                    </label>
                    <Field
                      name="city"
                      className={`field-base field-focus ${
                        touched.city && errors.city ? "field-error" : ""
                      }`}
                    />
                    <ErrorMessage
                      name="city"
                      component="div"
                      className="error-text"
                    />
                  </div>
                  {/* city */}

                  <div className="w-5/12">
                    <div className="mb-4">
                      <label htmlFor="province" className="label-class">
                        Province
                      </label>
                      <Field
                        name="province"
                        className={`field-base field-focus ${
                          touched.province && errors.province
                            ? "field-error"
                            : ""
                        }`}
                      />
                      <ErrorMessage
                        name="province"
                        component="div"
                        className="error-text"
                      />
                    </div>
                  </div>
                  {/* province */}

                  <div className="w-2/12">
                    <div className="mb-4">
                      <label htmlFor="postalCode" className="label-class">
                        Postal Code
                      </label>
                      <Field
                        name="postalCode"
                        className={`field-base field-focus ${
                          touched.postalCode && errors.postalCode
                            ? "field-error"
                            : ""
                        }`}
                      />
                      <ErrorMessage
                        name="postalCode"
                        component="div"
                        className="error-text"
                      />
                    </div>
                  </div>
                  {/* postalCode */}
                </div>
              </div>

              <div className="border-t mt-3 pt-3 mb-4  max-w-80">
                <label htmlFor="email" className="label-class label-required">
                  Email
                </label>
                <Field
                  name="email"
                  className={`field-base field-focus ${
                    touched.email && errors.email ? "field-error" : ""
                  }`}
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="error-text"
                />
              </div>

              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={
                  isSubmitting ? "btn btn-primary loading" : "btn btn-primary"
                }
                disabled={isSubmitting}
              >
                {isSubmitting ? "processing..." : "Email"}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CertificateCustomize;
