import React, { useState } from "react";
import { TopBar, NavBar } from "./components";
import { Outlet } from "react-router";
import { useSelector } from "react-redux";

const Dashboard = () => {
  const [isOpen, setIsOpen] = useState(true);
  const { isInit } = useSelector((state) => state.app);

  const toggleSideNav = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="h-screen w-full flex flex-col overflow-hidden">
      <TopBar className="z-10 relative" onOpenNavBarMobile={toggleSideNav} />
      <div className="flex flex-1 overflow-hidden">
        <NavBar onMobileClose={toggleSideNav} openMobile={isOpen} />
        <div className="overflow-y-auto flex-1 py-2 px-4 md:py-6 md:px-6 mt-16">
          {isInit ? (
            <Outlet />
          ) : (
            <>Not initialized, please refresh/login again...</>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
