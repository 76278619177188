import React from "react";
import { useParams, Link } from "react-router";
import { selectCertificateById } from "features/certificates/certificatesSlice";
import { useSelector } from "react-redux";
import { useInsurers } from "hooks";
import { Spinner } from "components";
import { selectAllInsurers } from "features/insurers/insurersSlice";
import { selectCertificateInsurers } from "features/selectors";
import { format, parseISO } from "date-fns";
import { Button, Checkbox, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { getCertificatePdf } from "api/certificatesApi";
import { toast } from "react-toastify";

const Contact = ({ contact }) => {
  const { name, address, city, province, postalCode, phone, email } =
    contact || {};
  return (
    <>
      <h6 className="font-medium text-gray-600">{name}</h6>
      <p className="text-gray-600">
        {address}, <br />
        {city} {province} {postalCode}
      </p>
      <p className="text-gray-600">
        {phone}, {email}
      </p>
    </>
  );
};

const InsuranceTypeLabel = ({ label, insurer }) => (
  <h5 className="font-bold uppercase">
    {label}{" "}
    <span className="font-medium">{(insurer && `(${insurer})`) || ""}</span>
  </h5>
);

const PoliciesDetails = ({ policies = [], field = "no", cb }) => (
  <ul>
    {policies.map((policy, index) => (
      <li className="text-gray-600" key={index}>
        {cb && policy?.[field] ? cb(policy[field]) : policy[field]}
      </li>
    ))}
  </ul>
);

const PolicyTypes = ({
  label = "",
  subLabel = "",
  checked = false,
  paddingLeft = false,
}) => (
  <li className="flex items-center">
    <div className={paddingLeft ? "pl-4" : ""}>
      <Checkbox size="small" disabled checked={checked} />
    </div>
    <div>
      <p className="uppercase">{label}</p>
      {subLabel && <p className="text-xs uppercase">{subLabel}</p>}
    </div>
  </li>
);

const PolicyLimits = ({ label = "", subLabel = "", value = "" }) => (
  <li className="flex my-2 border-b">
    <div className="w-8/12">
      <p className="uppercase">
        {label} {subLabel && <span className="text-xs">{subLabel}</span>}
      </p>
    </div>
    <div className="w-4/12">{value ? `$${value.toLocaleString()}` : ""}</div>
  </li>
);

const Certificate = () => {
  const { certificateId } = useParams();
  const { status: statusInsurers, error: errorInsurers } = useInsurers();
  const certificate = useSelector((state) =>
    selectCertificateById(state, certificateId)
  );
  const insurers = useSelector(selectAllInsurers);
  const certificateInsurers = useSelector(
    selectCertificateInsurers(certificate?._id)
  );

  const {
    generalLiability,
    automobileLiability,
    contractorsEquipment,
    workersCompensationEmployersLiability,
    motorTruckCargo,
    otherLiability,
    createdOn,
    insured,
    holder,
    holderLabel,
    description,
    user,
  } = certificate || {};

  if (statusInsurers === "loading") return <Spinner />;
  if (statusInsurers === "failed")
    return <div className="text-red-600">Error: {errorInsurers}</div>;

  return (
    <>
      <div className="py-6 flex justify-between">
        <IconButton component={Link} to={"../"}>
          <ArrowBackIcon />
        </IconButton>
        <div className="flex gap-2">
          <Button
            onClick={() => {
              getCertificatePdf(certificateId)
                .then((data) => {
                  // Create a URL for the Blob
                  const blobURL = URL.createObjectURL(
                    new Blob([data], { type: "application/pdf" })
                  );

                  // Create a link element
                  const link = document.createElement("a");
                  link.href = blobURL;
                  link.download = `${certificateId}.pdf`; // Set the desired file name
                  document.body.appendChild(link);

                  // Trigger the download
                  link.click();

                  // Cleanup
                  document.body.removeChild(link);
                  URL.revokeObjectURL(blobURL);
                })
                .catch((err) => console.error(err));
            }}
            variant="outlined"
            color="primary"
          >
            Download
          </Button>

          <Button component={Link} to="./email" variant="outlined">
            Email
          </Button>

          <Button component={Link} to="./manage" variant="outlined">
            Update
          </Button>

          <Button
            component={Link}
            to="./manage?duplicate=true"
            variant="outlined"
          >
            Duplicate
          </Button>
        </div>
      </div>

      {certificate?.isCustomizable && (
        <p className="text-blue-600 font-medium my-8 text-center text-lg bg-blue-50 py-3">
          This certificate is customizable.{" "}
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => {
              const customizableUrl = `https://${window.location.host}/certificate/${certificateId}/customize?key=${certificate.key}`;
              navigator.clipboard
                .writeText(customizableUrl)
                .then(() => {
                  toast.success("URL copied");
                })
                .catch((err) => {
                  console.error(err);
                  toast.error("could not copy URL");
                });
            }}
          >
            Copy URL
          </Button>
        </p>
      )}

      <div className="flex justify-between border p-3">
        <h1 className="text-3xl font-medium">
          Certificate of Liability Insurance
        </h1>
        <div>
          <div className="uppercase text-gray-600 text-sm">Date</div>
          <div>{createdOn && format(parseISO(createdOn), "MMM do, yyyy")}</div>
        </div>
      </div>
      {/* row1 */}

      <div className="p-3 border">
        <p className="text-sm text-gray-600 mb-2">
          This certificate is issued as a matter of information only and confers
          no rights upon the certificate holder. This certificate does not
          affirmatively or negatively amend, extend or alter the coverage
          afforded by the policies below. This certificate of insurance does not
          constitute a contract between the issuing insurer(s), authorized
          representative or producer, and the certificate holder.
        </p>
        <p className="text-sm text-gray-600">
          IMPORTANT: If the certificate holder is an ADDITIONAL INSURED, the
          policy(ies) must be endorsed. If SUBROGATION IS WAIVED, subject to the
          terms and conditions of the policy, certain policies may require an
          endorsement. A statement on this certificate does not confer rights to
          the certificate holder in lieu of such endorsement(s).
        </p>
      </div>
      {/* row 2*/}

      <div className="p-3 border flex gap-3">
        <div className="w-1/2">
          <h5 className="font-bold uppercase">Broker</h5>
          <h6 className="font-medium text-gray-600">
            SCOTT ROAD INSURANCE SERVICES LTD.
          </h6>
          <p className="text-gray-600">
            Unit 107 7130 120 street
            <br />
            Surrey BC V3W 3M8 <br />
            Ph (604)597-2700, Fax (604) 597-2900
          </p>
          <h5 className="font-bold uppercase mt-5">Insured</h5>
          <Contact contact={insured} />
        </div>
        <div className="w-1/2">
          {insurers && certificateInsurers && certificateInsurers.length && (
            <>
              {certificateInsurers.map((certificateInsurer, index) => (
                <div className="my-1" key={index}>
                  <span className="uppercase text-sm text-gray-600">
                    Insurer
                  </span>
                  <span className="font-medium ml-1">
                    {String.fromCharCode(65 + index)}
                  </span>
                  <span className="ml-3">{certificateInsurer?.name}</span>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
      {/* row3 */}

      <div className="p-4 border">
        <h5 className="font-bold mb-2 uppercase">Coverages</h5>
        <p className="text-gray-600 text-sm">
          This is to certify that the policies of insurance listed below have
          been issued to the insured named above for the policy period
          indicated, notwithstanding any requirements, terms or condition or any
          contract or other document with respect to which this certificate may
          be issued or may pertain, the insurance afforded by the policies
          described herein is subject to all the terms, exclusions and
          conditions of such policies, limits shown may have been reduced by
          paid claims.
        </p>
      </div>
      {/* row4 */}

      <div className="p-4 border">
        <table className="w-full">
          <thead>
            <tr className="border-b">
              <td
                className="p-2 uppercase font-medium text-sm text-gray-600 max-w-sm"
                width="280"
              >
                Type of Insurance
              </td>
              <td className="p-2 uppercase font-medium text-sm text-gray-600">
                Policy Number
              </td>
              <td className="p-2 uppercase font-medium text-sm text-gray-600">
                Policy Effective
              </td>
              <td className="p-2 uppercase font-medium text-sm text-gray-600">
                Policy Expiry
              </td>
              <td className="p-2">
                <div className="uppercase font-medium text-sm text-gray-600">
                  Limits
                </div>
                <p className="text-gray-600 text-sm">
                  **All policy limits are in Canadian Funds**
                </p>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr className="border-b">
              <td className="p-2">
                <InsuranceTypeLabel
                  label="General Liability"
                  insurer={generalLiability?.insurer}
                />
                <ul>
                  <PolicyTypes
                    label="Commercial General Liability"
                    checked={
                      generalLiability?.insuranceTypes
                        ?.commercialGeneralLiability
                    }
                  />
                  <PolicyTypes
                    label="Claims Made"
                    checked={generalLiability?.insuranceTypes?.claimsMade}
                    paddingLeft={true}
                  />
                  <PolicyTypes
                    label="Occur"
                    checked={generalLiability?.insuranceTypes?.claimsOccur}
                    paddingLeft={true}
                  />
                  <PolicyTypes
                    label="Owner's & Contractors Prot"
                    checked={
                      generalLiability?.insuranceTypes?.ownersContractors
                    }
                  />
                  <PolicyTypes
                    label="Employers Liability"
                    checked={
                      generalLiability?.insuranceTypes?.employersLiability
                    }
                  />
                  <PolicyTypes
                    label="Contingent Employers Liability"
                    subLabel="With respect to CDN OPS"
                    checked={
                      generalLiability?.insuranceTypes
                        ?.contingentEmployersLiability
                    }
                  />
                </ul>
              </td>
              <td className="p-2">
                <PoliciesDetails
                  policies={generalLiability?.policies}
                  field="no"
                />
              </td>
              <td className="p-2">
                <PoliciesDetails
                  policies={generalLiability?.policies}
                  field="effective"
                  cb={(v) => format(parseISO(v), "MMM do, yyyy")}
                />
              </td>
              <td className="p-2">
                <PoliciesDetails
                  policies={generalLiability?.policies}
                  field="expiry"
                  cb={(v) => format(parseISO(v), "MMM do, yyyy")}
                />
              </td>
              <td className="p-2">
                <ul className="text-gray-600">
                  <PolicyLimits
                    label="Each Occurrence"
                    value={generalLiability?.limits?.eachOccurrence}
                  />
                  <PolicyLimits
                    label="General Aggregate"
                    value={generalLiability?.limits?.generalAggregate}
                  />
                  <PolicyLimits
                    label="Products-Comp/OP Agg"
                    value={generalLiability?.limits?.products}
                  />
                  <PolicyLimits
                    label="Personal & ADV Injury"
                    value={generalLiability?.limits?.personal}
                  />
                  <PolicyLimits
                    label="Fire Damage"
                    subLabel="(Any one fire)"
                    value={generalLiability?.limits?.fireDamage}
                  />
                  <PolicyLimits
                    label="Med Exp"
                    subLabel="(Any one person)"
                    value={generalLiability?.limits?.medExpiry}
                  />
                </ul>
                {/*<pre>{JSON.stringify(generalLiability, null, 2)}</pre>*/}
              </td>
            </tr>
            {/* generalLiability */}

            <tr className="border-b">
              <td className="p-2">
                <InsuranceTypeLabel
                  label="Automobile Liability"
                  insurer={automobileLiability?.insurer}
                />
                <ul>
                  <PolicyTypes
                    label="Any Auto"
                    checked={automobileLiability?.insuranceTypes?.anyAuto}
                  />
                  <PolicyTypes
                    label="All Owned/Leased Autos"
                    checked={
                      automobileLiability?.insuranceTypes?.allOwnedLeasedAutos
                    }
                  />
                  <PolicyTypes
                    label="Scheduled Autos"
                    checked={
                      automobileLiability?.insuranceTypes?.scheduledAutos
                    }
                  />

                  <PolicyTypes
                    label="Hired Autos"
                    checked={automobileLiability?.insuranceTypes?.hiredAutos}
                  />

                  <PolicyTypes
                    label="Non-owned Auto/Trailer Liability"
                    checked={
                      automobileLiability?.insuranceTypes
                        ?.nonOwnedAutoTrailerLiability
                    }
                  />
                </ul>

                {/*<pre>{JSON.stringify(automobileLiability, null, 2)}</pre>*/}
              </td>
              <td className="p-2">
                <PoliciesDetails
                  policies={automobileLiability?.policies}
                  field="no"
                />
              </td>
              <td className="p-2">
                <PoliciesDetails
                  policies={automobileLiability?.policies}
                  field="effective"
                  cb={(v) => format(parseISO(v), "MMM do, yyyy")}
                />
              </td>
              <td className="p-2">
                <PoliciesDetails
                  policies={automobileLiability?.policies}
                  field="expiry"
                  cb={(v) => format(parseISO(v), "MMM do, yyyy")}
                />
              </td>
              <td className="p-2">
                <ul className="text-gray-600">
                  <PolicyLimits
                    label="Combined Single Limit"
                    value={automobileLiability?.limits?.combinedSingleLimit}
                  />
                  <PolicyLimits
                    label="Bodily Injury"
                    subLabel="(Per person)"
                    value={automobileLiability?.limits?.bodilyInjuryPerPerson}
                  />
                  <PolicyLimits
                    label="Bodily Injury"
                    subLabel="(Per accident)"
                    value={automobileLiability?.limits?.bodilyInjuryPerAccident}
                  />
                  <PolicyLimits
                    label="Non-owned Auto Liability"
                    value={automobileLiability?.limits?.nonOwnedAutoLiability}
                  />
                </ul>
              </td>
            </tr>
            {/* automobileLiability */}

            <tr className="border-b">
              <td className="p-2">
                <InsuranceTypeLabel
                  label="Contractors Equipment"
                  insurer={contractorsEquipment?.insurer}
                />
                <ul>
                  <PolicyTypes
                    label="Trailer Interchange physical damage, Includes coverage to non-owned trailers, containers and chassis."
                    checked={
                      contractorsEquipment?.insuranceTypes
                        ?.trailerPhysicalDamage
                    }
                  />
                </ul>

                {/*<pre>{JSON.stringify(contractorsEquipment, null, 2)}</pre>*/}
              </td>
              <td className="p-2">
                <PoliciesDetails
                  policies={contractorsEquipment?.policies}
                  field="no"
                />
              </td>
              <td className="p-2">
                <PoliciesDetails
                  policies={contractorsEquipment?.policies}
                  field="effective"
                  cb={(v) => format(parseISO(v), "MMM do, yyyy")}
                />
              </td>
              <td className="p-2">
                <PoliciesDetails
                  policies={contractorsEquipment?.policies}
                  field="expiry"
                  cb={(v) => format(parseISO(v), "MMM do, yyyy")}
                />
              </td>
              <td className="p-2">
                <ul className="text-gray-600">
                  <PolicyLimits
                    label="Limit"
                    value={contractorsEquipment?.limits?.maxAnyoneTrailerACV}
                  />
                  <PolicyLimits
                    label="Deductible"
                    value={contractorsEquipment?.limits?.deductible}
                  />
                </ul>
              </td>
            </tr>
            {/* contractorsEquipment */}

            <tr className="border-b">
              <td className="p-2">
                <InsuranceTypeLabel
                  label="Worker Compensation and Employers Liability"
                  insurer={workersCompensationEmployersLiability?.insurer}
                />
                <ul>
                  <PolicyTypes
                    label="The proprietor/Partners/Executive (Inc)"
                    checked={
                      workersCompensationEmployersLiability?.insuranceTypes
                        ?.proprietorPartnersExecutiveIncluded
                    }
                  />
                  <PolicyTypes
                    label="Officers are (Excl)"
                    checked={
                      workersCompensationEmployersLiability?.insuranceTypes
                        ?.officersExcluded
                    }
                  />
                </ul>

                {/*<pre>
                  {JSON.stringify(
                    workersCompensationEmployersLiability,
                    null,
                    2
                  )}
                </pre>*/}
              </td>
              <td className="p-2">
                <PoliciesDetails
                  policies={workersCompensationEmployersLiability?.policies}
                  field="no"
                />
              </td>
              <td className="p-2">
                <PoliciesDetails
                  policies={workersCompensationEmployersLiability?.policies}
                  field="effective"
                  cb={(v) => format(parseISO(v), "MMM do, yyyy")}
                />
              </td>
              <td className="p-2">
                <PoliciesDetails
                  policies={workersCompensationEmployersLiability?.policies}
                  field="expiry"
                  cb={(v) => format(parseISO(v), "MMM do, yyyy")}
                />
              </td>
              <td className="p-2">
                <ul className="text-gray-600">
                  <PolicyTypes
                    label="Statutory Limits"
                    checked={
                      workersCompensationEmployersLiability?.insuranceTypes
                        ?.statutoryLimits
                    }
                  />
                  <PolicyTypes
                    label="Other"
                    checked={
                      workersCompensationEmployersLiability?.insuranceTypes
                        ?.otherLimits
                    }
                  />
                  <PolicyLimits
                    label="EL each accident"
                    value={
                      workersCompensationEmployersLiability?.limits
                        ?.elEachAccident
                    }
                  />
                  <PolicyLimits
                    label="EL disease - policy limit"
                    value={
                      workersCompensationEmployersLiability?.limits
                        ?.elDiseasePolicyLimit
                    }
                  />
                  <PolicyLimits
                    label="EL disease - ea employee"
                    value={
                      workersCompensationEmployersLiability?.limits
                        ?.elDiseaseEmployee
                    }
                  />
                </ul>
              </td>
            </tr>
            {/* workersCompensationEmployersLiability */}

            <tr className="border-b">
              <td className="p-2">
                <InsuranceTypeLabel
                  label="Motor Truck Cargo"
                  insurer={motorTruckCargo?.insurer}
                />

                {/*<pre>{JSON.stringify(motorTruckCargo, null, 2)}</pre>*/}
              </td>
              <td className="p-2">
                <PoliciesDetails
                  policies={motorTruckCargo?.policies}
                  field="no"
                />
              </td>
              <td className="p-2">
                <PoliciesDetails
                  policies={motorTruckCargo?.policies}
                  field="effective"
                  cb={(v) => format(parseISO(v), "MMM do, yyyy")}
                />
              </td>
              <td className="p-2">
                <PoliciesDetails
                  policies={motorTruckCargo?.policies}
                  field="expiry"
                  cb={(v) => format(parseISO(v), "MMM do, yyyy")}
                />
              </td>
              <td className="p-2">
                <ul className="text-gray-600">
                  <PolicyLimits
                    label="Limit"
                    value={motorTruckCargo?.limits?.eachOccurrence}
                  />
                  <PolicyLimits
                    label="Deductible"
                    value={motorTruckCargo?.limits?.broadFormDeductible}
                  />
                  <PolicyLimits
                    label="Reefer Breakdown Ded"
                    value={motorTruckCargo?.limits?.reeferBreakdownDeductible}
                  />
                  {motorTruckCargo?.limits?.reeferBreakdownIncluded && (
                    <li className="flex my-2 border-b">
                      <div className="w-8/12">
                        <p className="uppercase">Refer Breakdown</p>
                      </div>
                      <div className="w-4/12 uppercase">Included</div>
                    </li>
                  )}
                </ul>
              </td>
            </tr>
            {/* motorTruckCargo */}

            <tr>
              <td className="p-2">
                <InsuranceTypeLabel
                  label="Other"
                  insurer={otherLiability?.insurer}
                />
                {otherLiability?.insuranceTypes?.custom && (
                  <p>
                    {otherLiability?.insuranceTypes?.custom}{" "}
                    {otherLiability.insurer ? (
                      <>({otherLiability.insurer})</>
                    ) : (
                      ""
                    )}
                  </p>
                )}
                {otherLiability?.insuranceTypes?.custom2 && (
                  <p>
                    {otherLiability?.insuranceTypes?.custom2}{" "}
                    {otherLiability.insurer2 ? (
                      <>({otherLiability.insurer2})</>
                    ) : (
                      ""
                    )}
                  </p>
                )}
              </td>
              <td className="p-2">
                <PoliciesDetails
                  policies={otherLiability?.policies}
                  field="no"
                />
              </td>
              <td className="p-2">
                <PoliciesDetails
                  policies={otherLiability?.policies}
                  field="effective"
                  cb={(v) => format(parseISO(v), "MMM do, yyyy")}
                />
              </td>
              <td className="p-2">
                <PoliciesDetails
                  policies={otherLiability?.policies}
                  field="expiry"
                  cb={(v) => format(parseISO(v), "MMM do, yyyy")}
                />
              </td>
              <td className="p-2">
                <ul className="text-gray-600">
                  <PolicyLimits
                    label="Limit"
                    value={otherLiability?.limits?.limit}
                  />
                  <PolicyLimits
                    label="Deductible"
                    value={otherLiability?.limits?.deductible}
                  />
                </ul>
              </td>
            </tr>
            {/* otherLiability */}
          </tbody>
        </table>
      </div>
      {/* row INSURANCES */}

      <div className="p-4 border">
        <p className="text-gray-600 mb-1">
          DESCRIPTION OF OPERATIONS/LOCATIONS/VEHICLES (Attach ACORD 101,
          Additional Remarks Schedule, If more space is required)
        </p>
        <p>{description}</p>
      </div>
      {/* row */}

      <div className="p-4 border flex">
        <div className="w-1/2">
          <h5 className="font-bold uppercase">
            {holderLabel || "Certificate Holder"}
          </h5>
          <Contact contact={holder} />
        </div>
        <div className="w-1/2">
          <h5 className="font-bold uppercase">Cancellation</h5>
          <p className="text-sm text-gray-600">
            Should any of the above described policies be cancelled before the
            expiration date thereof, notice will be delivered in accordance with
            the policy provisions. 30 day notice of cancellation
          </p>
          <p className="uppercase my-3">AUTHORIZED REPRESENTATIVE</p>
          <div>
            <img src={user?.signatureUrl} alt={user?.name} />
          </div>
          <p className="text-gray-600 mt-3">{user?.name}</p>
        </div>
      </div>
      {/* row */}
    </>
  );
};

export default Certificate;
