import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import * as certificateApi from "api/certificatesApi";

const certificatesAdaptor = createEntityAdapter({
  selectId: (certificate) => certificate._id,
});

export const getCertificatesByContactId = createAsyncThunk(
  "certificates/getCertificatesByContactId",
  async (contactId, { rejectWithValue }) => {
    try {
      const certificates = await certificateApi.getCertificates(contactId);
      return { contactId, certificates };
    } catch (err) {
      return rejectWithValue("Failed to fetch certificates");
    }
  }
);

export const postCertificate = createAsyncThunk(
  "certificates/postCertificate",
  async (data) => await certificateApi.postCertificate(data)
);

export const updateCertificate = createAsyncThunk(
  "certificates/updateCertificate",
  async (data) => await certificateApi.updateCertificate(data)
);

const initialState = certificatesAdaptor.getInitialState({
  status: "idle",
  error: null,
  fetchedContacts: [],
});

const certificatesSlice = createSlice({
  name: "certificates",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getCertificatesByContactId.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getCertificatesByContactId.fulfilled, (state, action) => {
        state.status = "succeeded";
        const { contactId, certificates } = action.payload;

        // Add contactId to fetchedContacts
        if (!state.fetchedContacts.includes(contactId)) {
          state.fetchedContacts.push(contactId);
        }

        // Add certificates to the state
        certificatesAdaptor.upsertMany(state, certificates);
      })
      .addCase(getCertificatesByContactId.rejected, (state, action) => {
        state.status = "failed";
        state.error = action?.error?.message;
      })
      .addCase(postCertificate.fulfilled, (state, action) => {
        state.status = "succeeded";
        certificatesAdaptor.addOne(state, action.payload);
      })
      .addCase(updateCertificate.fulfilled, (state, action) => {
        const { _id, ...changes } = action.payload;
        state.status = "succeeded";
        certificatesAdaptor.updateOne(state, {
          id: _id,
          changes,
        });
      });
  },
});

export default certificatesSlice.reducer;

// Export default selectors
export const {
  selectAll: selectAllCertificates,
  selectById: selectCertificateById,
  selectEntities: selectCertificateEntities,
} = certificatesAdaptor.getSelectors((state) => state.certificates);

export const selectFetchedContacts = (state) =>
  state.certificates.fetchedContacts;
