import axios from "./axios";

const baseUrl = "/users";

export const postUser = (user = {}) => {
  const { _id: id } = user;

  return id ? axios.put(`${baseUrl}/${id}`) : axios.post(baseUrl);
};

export const loginUser = (data) => axios.post(`${baseUrl}/login`, data);

export const profile = () => axios.get(`${baseUrl}/profile`);

export const getUsers = () => axios.get(baseUrl);
