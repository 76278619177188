import React from "react";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import certificateSchema from "./schema";
import { CheckboxBoolean, Spinner } from "components";
import Datetime from "react-datetime";
import moment from "moment";
import { useParams, useNavigate, Link, useSearchParams } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  IconButton,
  Grid,
  Autocomplete,
  TextField,
} from "@mui/material";
import {
  selectAllContacts,
  selectContactById,
} from "features/contacts/contactsSlice";
import { selectAllInsurers } from "features/insurers/insurersSlice";
import { useContacts, useInsurers } from "hooks";
import { selectCertificateById } from "features/certificates/certificatesSlice";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  postCertificate,
  updateCertificate,
} from "features/certificates/certificatesSlice";

const alphaList = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"];

const CertificateManage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { contactId, certificateId } = useParams();
  const [searchParams] = useSearchParams();
  const currentContact = useSelector((state) =>
    selectContactById(state, contactId)
  );
  const certificate = useSelector((state) =>
    selectCertificateById(state, certificateId)
  );
  const { status: statusContacts, error: errorContacts } = useContacts();
  const { status: statusInsurers, error: errorInsurers } = useInsurers();

  const contacts = useSelector(selectAllContacts);
  const insurers = useSelector(selectAllInsurers);

  let defaultValues = {
    createdOn: "",
    insured: "",
    insuredSuggester: "",
    holderLabel: "CERTIFICATE HOLDER",
    holder: "",
    holderSuggester: "",
    insurers: [""],
    description: "",
    generalLiability: {
      insurer: "",
      insuranceTypes: {
        commercialGeneralLiability: false,
        claimsMade: false,
        claimsOccur: false,
        ownersContractors: false,
        employersLiability: false,
        contingentEmployersLiability: false,
      },
      limits: {
        generalAggregate: "",
        products: "",
        personal: "",
        eachOccurrence: "",
        fireDamage: "",
        medExpiry: "",
      },
      policies: [],
    },
    automobileLiability: {
      insurer: "",
      insuranceTypes: {
        anyAuto: false,
        allOwnedLeasedAutos: false,
        scheduledAutos: false,
        hiredAutos: false,
        nonOwnedAutoTrailerLiability: false,
      },
      limits: {
        combinedSingleLimit: "",
        bodilyInjuryPerPerson: "",
        bodilyInjuryPerAccident: "",
        nonOwnedAutoLiability: "",
      },
      policies: [],
    },
    contractorsEquipment: {
      insurer: "",
      insuranceTypes: {
        trailerPhysicalDamage: false,
      },
      limits: {
        maxAnyoneTrailerACV: "",
        deductible: "",
      },
      policies: [],
    },
    workersCompensationEmployersLiability: {
      insurer: "",
      insuranceTypes: {
        proprietorPartnersExecutiveIncluded: false,
        officersExcluded: false,
        statutoryLimits: false,
        otherLimits: false,
      },
      limits: {
        elEachAccident: "",
        elDiseasePolicyLimit: "",
        elDiseaseEmployee: "",
      },
      policies: [],
    },
    motorTruckCargo: {
      insurer: "",
      limits: {
        eachOccurrence: "",
        broadFormDeductible: "2500",
        reeferBreakdownDeductible: "",
        reeferBreakdownIncluded: false,
      },
      policies: [],
    },
    otherLiability: {
      insurer: "",
      insurer2: "",
      insuranceTypes: {
        custom: "",
      },
      limits: {
        limit: "",
        deductible: "",
      },
      policies: [],
    },
  };

  if (statusContacts === "loading") return <Spinner />;
  if (statusContacts === "failed")
    return <div className="text-red-600">Error: {errorContacts}</div>;

  if (statusInsurers === "loading") return <Spinner />;
  if (statusInsurers === "failed")
    return <div className="text-red-600">Error: {errorInsurers}</div>;

  return (
    <>
      <div className="flex gap-2 items-center mb-6">
        <IconButton component={Link} to={"../"}>
          <ArrowBackIcon />
        </IconButton>
        <h1 className="text-4xl font-bold">
          {certificate?._id ? `Update certificate` : "Add new certificate"}
        </h1>
      </div>

      <Formik
        initialValues={{
          ...defaultValues,
          ...certificate,
          insured: currentContact,
        }}
        validationSchema={certificateSchema}
        onSubmit={({ _id, ...values }, { setSubmitting }) => {
          const data =
            searchParams.get("duplicate") === "true"
              ? values
              : { _id, ...values };

          setSubmitting(true);
          const dispatchMethod =
            certificate && searchParams.get("duplicate") !== "true"
              ? updateCertificate
              : postCertificate;

          dispatch(dispatchMethod(data))
            .unwrap()
            .then(({ insured, _id }) => {
              if (typeof insured === "object" && insured !== null) {
                navigate(
                  `/dashboard/contacts/${insured?._id}/certificates/${_id}`
                );
              } else {
                navigate(`/dashboard/contacts/${insured}/certificates/${_id}`);
              }
            })
            .catch((err) => console.log(err))
            .finally(() => setSubmitting(false));
        }}
      >
        {({ isSubmitting, values, setFieldValue, errors, touched }) => (
          <Form>
            <Field type="hidden" name="insured" className="form-control" />
            <Field type="hidden" name="holder" className="form-control" />

            <div className="mb-6 max-w-sm">
              <label htmlFor="name" className="label-class label-required">
                Issued On
              </label>
              <Datetime
                inputProps={{
                  name: "createdOn",
                  id: "createdOn",
                  autoComplete: "off",
                  placeholder: "MM/DD/YYYY",
                }}
                value={
                  values.createdOn &&
                  moment(values.createdOn).format("MM/DD/YYYY")
                }
                dateFormat="MM/DD/YYYY"
                timeFormat={false}
                closeOnSelect={true}
                onChange={(time) => {
                  setFieldValue("createdOn", time ? time : null);
                }}
                className={`field-base field-focus ${
                  touched.createdOn && errors.createdOn ? "field-error" : ""
                }`}
              />
              <ErrorMessage
                name="createdOn"
                component="div"
                className="error-text"
              />
            </div>

            <div className="mb-4">
              <Autocomplete
                value={values.insured}
                options={contacts || []}
                getOptionLabel={(option) => option.name || ""}
                isOptionEqualToValue={(option, value) =>
                  option._id === value._id
                }
                onChange={(event, newValue) =>
                  setFieldValue("insured", newValue)
                }
                renderInput={(params) => (
                  <TextField {...params} label="Select Insured" />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option._id}>
                    {option.name}
                  </li>
                )}
              />
            </div>

            <div className="mb-6">
              <label htmlFor="name" className="label-class">
                Certificate Holder Label
              </label>
              <Field
                name="holderLabel"
                placeholder="Layout holder Label"
                className={`field-base field-focus ${
                  touched.holderLabel && errors.holderLabel ? "field-error" : ""
                }`}
              />
              <ErrorMessage
                name="holderLabel"
                component="div"
                className="error-text"
              />
            </div>

            <div className="mb-4">
              <Autocomplete
                value={values.holder}
                options={contacts || []}
                getOptionLabel={(option) => option.name || ""}
                isOptionEqualToValue={(option, value) =>
                  option._id === value._id
                }
                onChange={(event, newValue) =>
                  setFieldValue("holder", newValue)
                }
                renderInput={(params) => (
                  <TextField {...params} label="Select Certificate Holder" />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option._id}>
                    {option.name}
                  </li>
                )}
              />
            </div>

            <h3 className="text-2xl mt-8 mb-4">Insurers affording coverage</h3>
            <FieldArray
              name="insurers"
              render={(arrayHelpers) => (
                <div>
                  {values.insurers && values.insurers.length > 0 ? (
                    values.insurers.map((insurer, index) => (
                      <div key={index} className="flex mb-2">
                        <div>
                          <Field
                            name={`insurers.${index}`}
                            component="select"
                            className={"field-base field-focus"}
                          >
                            <option></option>
                            {insurers.map(({ _id, name }) => (
                              <option key={_id} value={_id}>
                                {name}
                              </option>
                            ))}
                          </Field>
                        </div>
                        <IconButton onClick={() => arrayHelpers.remove(index)}>
                          <RemoveCircleIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => arrayHelpers.insert(index, "")}
                        >
                          <AddCircleIcon />
                        </IconButton>
                      </div>
                    ))
                  ) : (
                    <IconButton
                      type="button"
                      onClick={() => arrayHelpers.push("")}
                    >
                      <AddCircleIcon />
                    </IconButton>
                  )}
                </div>
              )}
              className={`field-base field-focus ${
                touched.insurers && errors.insurers ? "field-error" : ""
              }`}
            />

            <h3 className="text-2xl mt-8 mb-4">General Liability</h3>
            <div className="border-b mb-4 pb-4">
              <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                  <div className="mb-4">
                    <label className="label-class">INS. LTR</label>
                    <Field
                      name="generalLiability.insurer"
                      component="select"
                      className="field-base field-focus"
                    >
                      <option></option>
                      {alphaList.map((id) => (
                        <option
                          key={`generalLiability.insurer.${id}`}
                          value={id}
                        >
                          {id}
                        </option>
                      ))}
                    </Field>
                  </div>
                  <ul className="list-unstyled">
                    <li>
                      <div className="form-check">
                        <CheckboxBoolean
                          name="generalLiability.insuranceTypes.commercialGeneralLiability"
                          value={false}
                          className="form-check-input"
                          id="generalLiabilityInsuranceTypesCommercialGeneralLiability"
                        />
                        <label
                          className="label-inline-class"
                          htmlFor="generalLiabilityInsuranceTypesCommercialGeneralLiability"
                        >
                          Commercial General Liability
                        </label>
                      </div>
                      <ul className="list-unstyled ml-3">
                        <li>
                          <div className="form-check">
                            <CheckboxBoolean
                              name="generalLiability.insuranceTypes.claimsMade"
                              value={false}
                              className="form-check-input"
                              id="generalLiabilityInsuranceTypesClaimsMade"
                            />
                            <label
                              className="label-inline-class"
                              htmlFor="generalLiabilityInsuranceTypesClaimsMade"
                            >
                              Claims Made
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="form-check">
                            <CheckboxBoolean
                              name="generalLiability.insuranceTypes.claimsOccur"
                              value={false}
                              className="form-check-input"
                              id="generalLiabilityInsuranceTypesClaimsOccur"
                            />
                            <label
                              className="label-inline-class"
                              htmlFor="generalLiabilityInsuranceTypesClaimsOccur"
                            >
                              Claims Occur
                            </label>
                          </div>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <div className="form-check">
                        <CheckboxBoolean
                          name="generalLiability.insuranceTypes.ownersContractors"
                          value={false}
                          className="form-check-input"
                          id="generalLiabilityInsuranceTypesOwnersContractors"
                        />
                        <label
                          className="label-inline-class"
                          htmlFor="generalLiabilityInsuranceTypesOwnersContractors"
                        >
                          Owner &amp; Contractors Prot
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="form-check">
                        <CheckboxBoolean
                          name="generalLiability.insuranceTypes.employersLiability"
                          value={false}
                          className="form-check-input"
                          id="generalLiabilityInsuranceTypesEmployersLiability"
                        />
                        <label
                          className="label-inline-class"
                          htmlFor="generalLiabilityInsuranceTypesEmployersLiability"
                        >
                          Employers Liability
                        </label>
                      </div>
                    </li>

                    <li>
                      <div className="form-check">
                        <CheckboxBoolean
                          name="generalLiability.insuranceTypes.contingentEmployersLiability"
                          value={false}
                          className="form-check-input"
                          id="generalLiabilityInsuranceTypesContingentEmployersLiability"
                        />
                        <label
                          className="label-inline-class"
                          htmlFor="generalLiabilityInsuranceTypesContingentEmployersLiability"
                        >
                          Contingent Employers Liability
                        </label>
                      </div>
                    </li>
                  </ul>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FieldArray
                    name="generalLiability.policies"
                    render={(arrayHelpers) => (
                      <>
                        {values.generalLiability.policies &&
                        values.generalLiability.policies.length ? (
                          values.generalLiability.policies.map(
                            (policy, index) => (
                              <div key={index}>
                                <div className="flex gap-2 mb-2">
                                  <div>
                                    <Field
                                      name={`generalLiability.policies[${index}].no`}
                                      placeholder="Policy number"
                                      className="field-base field-focus"
                                    />
                                    <ErrorMessage
                                      name={`generalLiability.policies.${index}.no`}
                                      component="div"
                                      className="error-text"
                                    />
                                  </div>
                                  <div>
                                    <Datetime
                                      inputProps={{
                                        name: `generalLiability.policies.${index}.effective`,
                                        id: `generalLiabilityPolicies${index}Effective`,
                                        autoComplete: "off",
                                        placeholder: "Effective MM/DD/YYYY",
                                      }}
                                      value={
                                        policy.effective &&
                                        moment(policy.effective).format(
                                          "MM/DD/YYYY"
                                        )
                                      }
                                      dateFormat="MM/DD/YYYY"
                                      timeFormat={false}
                                      closeOnSelect={true}
                                      onChange={(time) => {
                                        setFieldValue(
                                          `generalLiability.policies.${index}.effective`,
                                          time ? time : null
                                        );
                                      }}
                                      className="field-base field-focus"
                                    />
                                    <ErrorMessage
                                      name={`generalLiability.policies.${index}.effective`}
                                      component="div"
                                      className="error-text"
                                    />
                                  </div>
                                  <div>
                                    <Datetime
                                      inputProps={{
                                        name: `generalLiability.policies.${index}.expiry`,
                                        id: `generalLiabilityPolicies${index}Expiry`,
                                        autoComplete: "off",
                                        placeholder: "Expiry MM/DD/YYYY",
                                      }}
                                      value={
                                        policy.expiry &&
                                        moment(policy.expiry).format(
                                          "MM/DD/YYYY"
                                        )
                                      }
                                      dateFormat="MM/DD/YYYY"
                                      timeFormat={false}
                                      closeOnSelect={true}
                                      onChange={(time) => {
                                        setFieldValue(
                                          `generalLiability.policies.${index}.expiry`,
                                          time ? time : null
                                        );
                                      }}
                                      className="field-base field-focus"
                                    />
                                    <ErrorMessage
                                      name={`generalLiability.policies.${index}.expiry`}
                                      component="div"
                                      className="error-text"
                                    />
                                  </div>
                                  <div className="col-md-3">
                                    <IconButton
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      <RemoveCircleIcon />
                                    </IconButton>{" "}
                                    <IconButton
                                      onClick={() =>
                                        arrayHelpers.push({
                                          no: "",
                                          effective: "",
                                          expiry: "",
                                        })
                                      }
                                    >
                                      <AddCircleIcon />
                                    </IconButton>
                                  </div>
                                </div>
                              </div>
                            )
                          )
                        ) : (
                          <Button
                            variant="outlined"
                            size="small"
                            color="primary"
                            onClick={() =>
                              arrayHelpers.push({
                                no: "",
                                effective: null,
                                expiry: null,
                              })
                            }
                          >
                            Add policy
                          </Button>
                        )}
                      </>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <div className="mb-4">
                    <label className="label-class">Each Occurrence</label>
                    <Field
                      type="number"
                      name="generalLiability.limits.eachOccurrence"
                      className="field-base field-focus"
                    />
                    <ErrorMessage
                      name="generalLiability.limits.eachOccurrence"
                      component="div"
                      className="error-text"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="label-class">General Aggregate</label>
                    <Field
                      type="number"
                      name="generalLiability.limits.generalAggregate"
                      className="field-base field-focus"
                    />
                    <ErrorMessage
                      name="generalLiability.limits.generalAggregate"
                      component="div"
                      className="error-text"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="label-class">Products-Comp/OP AGG</label>
                    <Field
                      type="number"
                      name="generalLiability.limits.products"
                      className="field-base field-focus"
                    />
                    <ErrorMessage
                      name="generalLiability.limits.products"
                      component="div"
                      className="error-text"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="label-class">
                      Personal &amp; Adv. Injury
                    </label>
                    <Field
                      type="number"
                      name="generalLiability.limits.personal"
                      className="field-base field-focus"
                    />
                    <ErrorMessage
                      name="generalLiability.limits.personal"
                      component="div"
                      className="error-text"
                    />
                  </div>

                  <div className="mb-4">
                    <label className="label-class">
                      Fire Damage (Any one fire)
                    </label>
                    <Field
                      type="number"
                      name="generalLiability.limits.fireDamage"
                      className="field-base field-focus"
                    />
                    <ErrorMessage
                      name="generalLiability.limits.fireDamage"
                      component="div"
                      className="error-text"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="label-class">
                      Med Exp (Any one person)
                    </label>
                    <Field
                      type="number"
                      name="generalLiability.limits.medExpiry"
                      className="field-base field-focus"
                    />
                    <ErrorMessage
                      name="generalLiability.limits.medExpiry"
                      component="div"
                      className="error-text"
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
            {/* generalLiability */}

            <h3 className="text-2xl mt-8 mb-4">Automobile Liability</h3>
            <div className="border-b mb-4 pb-4">
              <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                  <div className="mb-4">
                    <label className="label-class">INS. LTR</label>
                    <Field
                      name="automobileLiability.insurer"
                      component="select"
                      className="field-base field-focus"
                    >
                      <option></option>
                      {alphaList.map((id) => (
                        <option
                          key={`automobileLiability.insurer.${id}`}
                          value={id}
                        >
                          {id}
                        </option>
                      ))}
                    </Field>
                  </div>
                  <ul className="list-unstyled">
                    <li>
                      <div className="form-check">
                        <CheckboxBoolean
                          name="automobileLiability.insuranceTypes.anyAuto"
                          value={false}
                          className="form-check-input"
                          id="automobileLiabilityInsuranceTypesAnyAuto"
                        />
                        <label
                          className="label-inline-class"
                          htmlFor="automobileLiabilityInsuranceTypesAnyAuto"
                        >
                          Any Auto
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="form-check">
                        <CheckboxBoolean
                          name="automobileLiability.insuranceTypes.allOwnedLeasedAutos"
                          value={false}
                          className="form-check-input"
                          id="automobileLiabilityInsuranceTypesAllOwnedLeasedAutos"
                        />
                        <label
                          className="label-inline-class"
                          htmlFor="automobileLiabilityInsuranceTypesAllOwnedLeasedAutos"
                        >
                          All Owned/Leased Autos
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="form-check">
                        <CheckboxBoolean
                          name="automobileLiability.insuranceTypes.scheduledAutos"
                          value={false}
                          className="form-check-input"
                          id="automobileLiabilityInsuranceTypesScheduledAutos"
                        />
                        <label
                          className="label-inline-class"
                          htmlFor="automobileLiabilityInsuranceTypesScheduledAutos"
                        >
                          Scheduled Autos
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="form-check">
                        <CheckboxBoolean
                          name="automobileLiability.insuranceTypes.hiredAutos"
                          value={false}
                          className="form-check-input"
                          id="automobileLiabilityInsuranceTypesHiredAutos"
                        />
                        <label
                          className="label-inline-class"
                          htmlFor="automobileLiabilityInsuranceTypesHiredAutos"
                        >
                          Hired Autos
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="form-check">
                        <CheckboxBoolean
                          name="automobileLiability.insuranceTypes.nonOwnedAutoTrailerLiability"
                          value={false}
                          className="form-check-input"
                          id="automobileLiabilityInsuranceTypesNonOwnedAutoTrailerLiability"
                        />
                        <label
                          className="label-inline-class"
                          htmlFor="automobileLiabilityInsuranceTypesNonOwnedAutoTrailerLiability"
                        >
                          Non-owned Auto/Trailer Liability
                        </label>
                      </div>
                    </li>
                  </ul>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FieldArray
                    name="automobileLiability.policies"
                    render={(arrayHelpers) => (
                      <>
                        {values.automobileLiability.policies &&
                        values.automobileLiability.policies.length ? (
                          values.automobileLiability.policies.map(
                            (policy, index) => (
                              <div key={index}>
                                <div className="flex gap-2 mb-2">
                                  <div>
                                    <Field
                                      name={`automobileLiability.policies[${index}].no`}
                                      placeholder="Policy number"
                                      className="field-base field-focus"
                                    />
                                    <ErrorMessage
                                      name={`automobileLiability.policies.${index}.no`}
                                      component="div"
                                      className="error-text"
                                    />
                                  </div>
                                  <div>
                                    <Datetime
                                      inputProps={{
                                        name: `automobileLiability.policies.${index}.effective`,
                                        id: `automobileLiabilityPolicies${index}Effective`,
                                        autoComplete: "off",
                                        placeholder: "Effective MM/DD/YYYY",
                                      }}
                                      value={
                                        policy.effective &&
                                        moment(policy.effective).format(
                                          "MM/DD/YYYY"
                                        )
                                      }
                                      dateFormat="MM/DD/YYYY"
                                      timeFormat={false}
                                      closeOnSelect={true}
                                      onChange={(time) => {
                                        setFieldValue(
                                          `automobileLiability.policies.${index}.effective`,
                                          time ? time : null
                                        );
                                      }}
                                      className="field-base field-focus"
                                    />
                                    <ErrorMessage
                                      name={`automobileLiability.policies.${index}.effective`}
                                      component="div"
                                      className="error-text"
                                    />
                                  </div>
                                  <div>
                                    <Datetime
                                      inputProps={{
                                        name: `automobileLiability.policies.${index}.expiry`,
                                        id: `automobileLiabilityPolicies${index}Expiry`,
                                        autoComplete: "off",
                                        placeholder: "Expiry MM/DD/YYYY",
                                      }}
                                      value={
                                        policy.expiry &&
                                        moment(policy.expiry).format(
                                          "MM/DD/YYYY"
                                        )
                                      }
                                      dateFormat="MM/DD/YYYY"
                                      timeFormat={false}
                                      closeOnSelect={true}
                                      onChange={(time) => {
                                        setFieldValue(
                                          `automobileLiability.policies.${index}.expiry`,
                                          time ? time : null
                                        );
                                      }}
                                      className="field-base field-focus"
                                    />
                                    <ErrorMessage
                                      name={`automobileLiability.policies.${index}.expiry`}
                                      component="div"
                                      className="error-text"
                                    />
                                  </div>
                                  <div className="col-md-3">
                                    <IconButton
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      <RemoveCircleIcon />
                                    </IconButton>{" "}
                                    <IconButton
                                      onClick={() =>
                                        arrayHelpers.push({
                                          no: "",
                                          effective: "",
                                          expiry: "",
                                        })
                                      }
                                    >
                                      <AddCircleIcon />
                                    </IconButton>
                                  </div>
                                </div>
                              </div>
                            )
                          )
                        ) : (
                          <Button
                            variant="outlined"
                            size="small"
                            color="primary"
                            onClick={() =>
                              arrayHelpers.push({
                                no: "",
                                effective: null,
                                expiry: null,
                              })
                            }
                          >
                            Add policy
                          </Button>
                        )}
                      </>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <div className="mb-4">
                    <label className="label-class">Combined Single Limit</label>
                    <Field
                      type="number"
                      name="automobileLiability.limits.combinedSingleLimit"
                      className="field-base field-focus"
                    />
                    <ErrorMessage
                      name="automobileLiability.limits.combinedSingleLimit"
                      component="div"
                      className="error-text"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="label-class">
                      Bodily Injury <small>Per person</small>
                    </label>
                    <Field
                      type="number"
                      name="automobileLiability.limits.bodilyInjuryPerPerson"
                      className="field-base field-focus"
                    />
                    <ErrorMessage
                      name="automobileLiability.limits.bodilyInjuryPerPerson"
                      component="div"
                      className="error-text"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="label-class">
                      Bodily Injury <small>Per accident</small>
                    </label>
                    <Field
                      type="number"
                      name="automobileLiability.limits.bodilyInjuryPerAccident"
                      className="field-base field-focus"
                    />
                    <ErrorMessage
                      name="automobileLiability.limits.bodilyInjuryPerAccident"
                      component="div"
                      className="error-text"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="label-class">
                      Non-owned auto liability
                    </label>
                    <Field
                      type="number"
                      name="automobileLiability.limits.nonOwnedAutoLiability"
                      className="field-base field-focus"
                    />
                    <ErrorMessage
                      name="automobileLiability.limits.nonOwnedAutoLiability"
                      component="div"
                      className="error-text"
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
            {/* automobileLiability */}

            <h3 className="text-2xl mt-8 mb-4">Contractors Equipment</h3>
            <div className="border-b mb-4 pb-4">
              <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                  <div className="mb-4">
                    <label className="label-class">INS. LTR</label>
                    <Field
                      name="contractorsEquipment.insurer"
                      component="select"
                      className="field-base field-focus"
                    >
                      <option></option>
                      {alphaList.map((id) => (
                        <option
                          key={`contractorsEquipment.insurer.${id}`}
                          value={id}
                        >
                          {id}
                        </option>
                      ))}
                    </Field>
                  </div>
                  <ul className="list-unstyled">
                    <li>
                      <div className="form-check">
                        <CheckboxBoolean
                          name="contractorsEquipment.insuranceTypes.trailerPhysicalDamage"
                          value={false}
                          className="form-check-input"
                          id="contractorsEquipmentInsuranceTypesTrailerPhysicalDamage"
                        />
                        <label
                          className="label-inline-class"
                          htmlFor="contractorsEquipmentInsuranceTypesTrailerPhysicalDamage"
                        >
                          Trailer Interchange Physical Damage, Includes Coverage
                          to Non-owned trailers, containers and chassis.
                        </label>
                      </div>
                    </li>
                  </ul>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FieldArray
                    name="contractorsEquipment.policies"
                    render={(arrayHelpers) => (
                      <>
                        {values.contractorsEquipment.policies &&
                        values.contractorsEquipment.policies.length ? (
                          values.contractorsEquipment.policies.map(
                            (policy, index) => (
                              <div key={index}>
                                <div className="flex gap-2 mb-2">
                                  <div>
                                    <Field
                                      name={`contractorsEquipment.policies[${index}].no`}
                                      placeholder="Policy number"
                                      className="field-base field-focus"
                                    />
                                    <ErrorMessage
                                      name={`contractorsEquipment.policies.${index}.no`}
                                      component="div"
                                      className="error-text"
                                    />
                                  </div>
                                  <div>
                                    <Datetime
                                      inputProps={{
                                        name: `contractorsEquipment.policies.${index}.effective`,
                                        id: `contractorsEquipmentPolicies${index}Effective`,
                                        autoComplete: "off",
                                        placeholder: "Effective MM/DD/YYYY",
                                      }}
                                      value={
                                        policy.effective &&
                                        moment(policy.effective).format(
                                          "MM/DD/YYYY"
                                        )
                                      }
                                      dateFormat="MM/DD/YYYY"
                                      timeFormat={false}
                                      closeOnSelect={true}
                                      onChange={(time) => {
                                        setFieldValue(
                                          `contractorsEquipment.policies.${index}.effective`,
                                          time ? time : null
                                        );
                                      }}
                                      className="field-base field-focus"
                                    />
                                    <ErrorMessage
                                      name={`contractorsEquipment.policies.${index}.effective`}
                                      component="div"
                                      className="error-text"
                                    />
                                  </div>
                                  <div>
                                    <Datetime
                                      inputProps={{
                                        name: `contractorsEquipment.policies.${index}.expiry`,
                                        id: `contractorsEquipmentPolicies${index}Expiry`,
                                        autoComplete: "off",
                                        placeholder: "Expiry MM/DD/YYYY",
                                      }}
                                      value={
                                        policy.expiry &&
                                        moment(policy.expiry).format(
                                          "MM/DD/YYYY"
                                        )
                                      }
                                      dateFormat="MM/DD/YYYY"
                                      timeFormat={false}
                                      closeOnSelect={true}
                                      onChange={(time) => {
                                        setFieldValue(
                                          `contractorsEquipment.policies.${index}.expiry`,
                                          time ? time : null
                                        );
                                      }}
                                      className="field-base field-focus"
                                    />
                                    <ErrorMessage
                                      name={`contractorsEquipment.policies.${index}.expiry`}
                                      component="div"
                                      className="error-text"
                                    />
                                  </div>
                                  <div className="col-md-3">
                                    <IconButton
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      <RemoveCircleIcon />
                                    </IconButton>{" "}
                                    <IconButton
                                      onClick={() =>
                                        arrayHelpers.push({
                                          no: "",
                                          effective: "",
                                          expiry: "",
                                        })
                                      }
                                    >
                                      <AddCircleIcon />
                                    </IconButton>
                                  </div>
                                </div>
                              </div>
                            )
                          )
                        ) : (
                          <Button
                            variant="outlined"
                            size="small"
                            color="primary"
                            onClick={() =>
                              arrayHelpers.push({
                                no: "",
                                effective: null,
                                expiry: null,
                              })
                            }
                          >
                            Add policy
                          </Button>
                        )}
                      </>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <div className="mb-4">
                    <label className="label-class">Limit</label>
                    <Field
                      type="number"
                      name="contractorsEquipment.limits.maxAnyoneTrailerACV"
                      className="field-base field-focus"
                    />
                    <ErrorMessage
                      name="contractorsEquipment.limits.maxAnyoneTrailerACV"
                      component="div"
                      className="error-text"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="label-class">Deductible</label>
                    <Field
                      type="number"
                      name="contractorsEquipment.limits.deductible"
                      className="field-base field-focus"
                    />
                    <ErrorMessage
                      name="contractorsEquipment.limits.deductible"
                      component="div"
                      className="error-text"
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
            {/* contractorEquipment */}

            <h3 className="text-2xl mt-8 mb-4">
              Workers Compensation &amp; Employers Liability
            </h3>
            <div className="border-b mb-4 pb-4">
              <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                  <div className="mb-4">
                    <label className="label-class">INS. LTR</label>
                    <Field
                      name="workersCompensationEmployersLiability.insurer"
                      component="select"
                      className="field-base field-focus"
                    >
                      <option></option>
                      {alphaList.map((id) => (
                        <option
                          key={`workersCompensationEmployersLiability.insurer.${id}`}
                          value={id}
                        >
                          {id}
                        </option>
                      ))}
                    </Field>
                  </div>
                  <ul className="list-unstyled">
                    <li>
                      <div className="form-check">
                        <CheckboxBoolean
                          name="workersCompensationEmployersLiability.insuranceTypes.proprietorPartnersExecutiveIncluded"
                          value={false}
                          className="form-check-input"
                          id="workersCompensationEmployersLiabilityInsuranceTypesProprietorPartnersExecutiveIncluded"
                        />
                        <label
                          className="label-inline-class"
                          htmlFor="workersCompensationEmployersLiabilityInsuranceTypesProprietorPartnersExecutiveIncluded"
                        >
                          The Proprietor/Partners/Executive (INCL)
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="form-check">
                        <CheckboxBoolean
                          name="workersCompensationEmployersLiability.insuranceTypes.officersExcluded"
                          value={false}
                          className="form-check-input"
                          id="workersCompensationEmployersLiabilityInsuranceTypesOfficersExcluded"
                        />
                        <label
                          className="label-inline-class"
                          htmlFor="workersCompensationEmployersLiabilityInsuranceTypesOfficersExcluded"
                        >
                          Officers Are (EXCL)
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="form-check">
                        <CheckboxBoolean
                          name="workersCompensationEmployersLiability.insuranceTypes.statutoryLimits"
                          value={false}
                          className="form-check-input"
                          id="workersCompensationEmployersLiabilityInsuranceTypesStatutoryLimits"
                        />
                        <label
                          className="label-inline-class"
                          htmlFor="workersCompensationEmployersLiabilityInsuranceTypesStatutoryLimits"
                        >
                          Statutory Limits
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="form-check">
                        <CheckboxBoolean
                          name="workersCompensationEmployersLiability.insuranceTypes.otherLimits"
                          value={false}
                          className="form-check-input"
                          id="workersCompensationEmployersLiabilityInsuranceTypesOtherLimits"
                        />
                        <label
                          className="label-inline-class"
                          htmlFor="workersCompensationEmployersLiabilityInsuranceTypesOtherLimits"
                        >
                          Other
                        </label>
                      </div>
                    </li>
                  </ul>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FieldArray
                    name="workersCompensationEmployersLiability.policies"
                    render={(arrayHelpers) => (
                      <>
                        {values.workersCompensationEmployersLiability
                          .policies &&
                        values.workersCompensationEmployersLiability.policies
                          .length ? (
                          values.workersCompensationEmployersLiability.policies.map(
                            (policy, index) => (
                              <div key={index}>
                                <div className="flex gap-2 mb-2">
                                  <div>
                                    <Field
                                      name={`workersCompensationEmployersLiability.policies[${index}].no`}
                                      placeholder="Policy number"
                                      className="field-base field-focus"
                                    />
                                    <ErrorMessage
                                      name={`workersCompensationEmployersLiability.policies.${index}.no`}
                                      component="div"
                                      className="error-text"
                                    />
                                  </div>
                                  <div>
                                    <Datetime
                                      inputProps={{
                                        name: `workersCompensationEmployersLiability.policies.${index}.effective`,
                                        id: `workersCompensationEmployersLiabilityPolicies${index}Effective`,
                                        autoComplete: "off",
                                        placeholder: "Effective MM/DD/YYYY",
                                      }}
                                      value={
                                        policy.effective &&
                                        moment(policy.effective).format(
                                          "MM/DD/YYYY"
                                        )
                                      }
                                      dateFormat="MM/DD/YYYY"
                                      timeFormat={false}
                                      closeOnSelect={true}
                                      onChange={(time) => {
                                        setFieldValue(
                                          `workersCompensationEmployersLiability.policies.${index}.effective`,
                                          time ? time : null
                                        );
                                      }}
                                      className="field-base field-focus"
                                    />
                                    <ErrorMessage
                                      name={`workersCompensationEmployersLiability.policies.${index}.effective`}
                                      component="div"
                                      className="error-text"
                                    />
                                  </div>
                                  <div>
                                    <Datetime
                                      inputProps={{
                                        name: `workersCompensationEmployersLiability.policies.${index}.expiry`,
                                        id: `workersCompensationEmployersLiabilityPolicies${index}Expiry`,
                                        autoComplete: "off",
                                        placeholder: "Expiry MM/DD/YYYY",
                                      }}
                                      value={
                                        policy.expiry &&
                                        moment(policy.expiry).format(
                                          "MM/DD/YYYY"
                                        )
                                      }
                                      dateFormat="MM/DD/YYYY"
                                      timeFormat={false}
                                      closeOnSelect={true}
                                      onChange={(time) => {
                                        setFieldValue(
                                          `workersCompensationEmployersLiability.policies.${index}.expiry`,
                                          time ? time : null
                                        );
                                      }}
                                      className="field-base field-focus"
                                    />
                                    <ErrorMessage
                                      name={`workersCompensationEmployersLiability.policies.${index}.expiry`}
                                      component="div"
                                      className="error-text"
                                    />
                                  </div>
                                  <div className="col-md-3">
                                    <IconButton
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      <RemoveCircleIcon />
                                    </IconButton>{" "}
                                    <IconButton
                                      onClick={() =>
                                        arrayHelpers.push({
                                          no: "",
                                          effective: "",
                                          expiry: "",
                                        })
                                      }
                                    >
                                      <AddCircleIcon />
                                    </IconButton>
                                  </div>
                                </div>
                              </div>
                            )
                          )
                        ) : (
                          <Button
                            variant="outlined"
                            size="small"
                            color="primary"
                            onClick={() =>
                              arrayHelpers.push({
                                no: "",
                                effective: null,
                                expiry: null,
                              })
                            }
                          >
                            Add policy
                          </Button>
                        )}
                      </>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <div className="mb-4">
                    <label className="label-class">EL each accident</label>
                    <Field
                      type="number"
                      name="workersCompensationEmployersLiability.limits.elEachAccident"
                      className="field-base field-focus"
                    />
                    <ErrorMessage
                      name="workersCompensationEmployersLiability.limits.elEachAccident"
                      component="div"
                      className="error-text"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="label-class">
                      EL Disease - Policy limit
                    </label>
                    <Field
                      type="number"
                      name="workersCompensationEmployersLiability.limits.elDiseasePolicyLimit"
                      className="field-base field-focus"
                    />
                    <ErrorMessage
                      name="workersCompensationEmployersLiability.limits.elDiseasePolicyLimit"
                      component="div"
                      className="error-text"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="label-class">
                      EL Disease-Each Employee
                    </label>
                    <Field
                      type="number"
                      name="workersCompensationEmployersLiability.limits.elDiseaseEmployee"
                      className="field-base field-focus"
                    />
                    <ErrorMessage
                      name="workersCompensationEmployersLiability.limits.elDiseaseEmployee"
                      component="div"
                      className="error-text"
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
            {/* Workers Compensation &amp; Employers Liability */}

            <h3 className="text-2xl mt-8 mb-4">Motor Truck Cargo</h3>
            <div className="border-b mb-4 pb-4">
              <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                  <div className="mb-4">
                    <label className="label-class">INS. LTR</label>
                    <Field
                      name="motorTruckCargo.insurer"
                      component="select"
                      className="field-base field-focus"
                    >
                      <option></option>
                      {alphaList.map((id) => (
                        <option
                          key={`motorTruckCargo.insurer.${id}`}
                          value={id}
                        >
                          {id}
                        </option>
                      ))}
                    </Field>
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FieldArray
                    name="motorTruckCargo.policies"
                    render={(arrayHelpers) => (
                      <>
                        {values.motorTruckCargo.policies &&
                        values.motorTruckCargo.policies.length ? (
                          values.motorTruckCargo.policies.map(
                            (policy, index) => (
                              <div key={index}>
                                <div className="flex gap-2 mb-2">
                                  <div>
                                    <Field
                                      name={`motorTruckCargo.policies[${index}].no`}
                                      placeholder="Policy number"
                                      className="field-base field-focus"
                                    />
                                    <ErrorMessage
                                      name={`motorTruckCargo.policies.${index}.no`}
                                      component="div"
                                      className="error-text"
                                    />
                                  </div>
                                  <div>
                                    <Datetime
                                      inputProps={{
                                        name: `motorTruckCargo.policies.${index}.effective`,
                                        id: `motorTruckCargoPolicies${index}Effective`,
                                        autoComplete: "off",
                                        placeholder: "Effective MM/DD/YYYY",
                                      }}
                                      value={
                                        policy.effective &&
                                        moment(policy.effective).format(
                                          "MM/DD/YYYY"
                                        )
                                      }
                                      dateFormat="MM/DD/YYYY"
                                      timeFormat={false}
                                      closeOnSelect={true}
                                      onChange={(time) => {
                                        setFieldValue(
                                          `motorTruckCargo.policies.${index}.effective`,
                                          time ? time : null
                                        );
                                      }}
                                      className="field-base field-focus"
                                    />
                                    <ErrorMessage
                                      name={`motorTruckCargo.policies.${index}.effective`}
                                      component="div"
                                      className="error-text"
                                    />
                                  </div>
                                  <div>
                                    <Datetime
                                      inputProps={{
                                        name: `motorTruckCargo.policies.${index}.expiry`,
                                        id: `motorTruckCargoPolicies${index}Expiry`,
                                        autoComplete: "off",
                                        placeholder: "Expiry MM/DD/YYYY",
                                      }}
                                      value={
                                        policy.expiry &&
                                        moment(policy.expiry).format(
                                          "MM/DD/YYYY"
                                        )
                                      }
                                      dateFormat="MM/DD/YYYY"
                                      timeFormat={false}
                                      closeOnSelect={true}
                                      onChange={(time) => {
                                        setFieldValue(
                                          `motorTruckCargo.policies.${index}.expiry`,
                                          time ? time : null
                                        );
                                      }}
                                      className="field-base field-focus"
                                    />
                                    <ErrorMessage
                                      name={`motorTruckCargo.policies.${index}.expiry`}
                                      component="div"
                                      className="error-text"
                                    />
                                  </div>
                                  <div className="col-md-3">
                                    <IconButton
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      <RemoveCircleIcon />
                                    </IconButton>{" "}
                                    <IconButton
                                      onClick={() =>
                                        arrayHelpers.push({
                                          no: "",
                                          effective: "",
                                          expiry: "",
                                        })
                                      }
                                    >
                                      <AddCircleIcon />
                                    </IconButton>
                                  </div>
                                </div>
                              </div>
                            )
                          )
                        ) : (
                          <Button
                            variant="outlined"
                            size="small"
                            color="primary"
                            onClick={() =>
                              arrayHelpers.push({
                                no: "",
                                effective: null,
                                expiry: null,
                              })
                            }
                          >
                            Add policy
                          </Button>
                        )}
                      </>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <div className="mb-4">
                    <label className="label-class">Limit</label>
                    <Field
                      type="number"
                      name="motorTruckCargo.limits.eachOccurrence"
                      className="field-base field-focus"
                    />
                    <ErrorMessage
                      name="motorTruckCargo.limits.eachOccurrence"
                      component="div"
                      className="error-text"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="label-class">Deductible</label>
                    <Field
                      type="number"
                      name="motorTruckCargo.limits.broadFormDeductible"
                      className="field-base field-focus"
                    />
                    <ErrorMessage
                      name="motorTruckCargo.limits.broadFormDeductible"
                      component="div"
                      className="error-text"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="label-class">Reefer breakdown Ded</label>
                    <Field
                      type="number"
                      name="motorTruckCargo.limits.reeferBreakdownDeductible"
                      className="field-base field-focus"
                    />
                    <ErrorMessage
                      name="motorTruckCargo.limits.reeferBreakdownDeductible"
                      component="div"
                      className="error-text"
                    />
                  </div>
                  <div className="form-check">
                    <CheckboxBoolean
                      name="motorTruckCargo.limits.reeferBreakdownIncluded"
                      value={false}
                      className="form-check-input"
                      id="motorTruckCargoLimitsReeferBreakdownIncluded"
                    />
                    <label
                      className="label-inline-class"
                      htmlFor="motorTruckCargoLimitsReeferBreakdownIncluded"
                    >
                      Refer Breakdown Included
                    </label>
                  </div>
                </Grid>
              </Grid>
            </div>
            {/* motorTruckCargo */}

            <h3 className="text-2xl mt-8 mb-4">Other</h3>
            <div className="border-b mb-4 pb-4">
              <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                  <div className="mb-4">
                    <label className="label-class">INS. LTR</label>
                    <Field
                      name="otherLiability.insurer"
                      component="select"
                      className="field-base field-focus"
                    >
                      <option></option>
                      {alphaList.map((id) => (
                        <option key={`otherLiability.insurer.${id}`} value={id}>
                          {id}
                        </option>
                      ))}
                    </Field>
                  </div>
                  <div className="mb-4">
                    <label className="label-class">Other Liability name</label>
                    <Field
                      type="text"
                      name="otherLiability.insuranceTypes.custom"
                      className="field-base field-focus"
                    />
                    <ErrorMessage
                      name="otherLiability.insuranceTypes.custom"
                      component="div"
                      className="error-text"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="label-class">INS. LTR (2)</label>
                    <Field
                      name="otherLiability.insurer2"
                      component="select"
                      className="field-base field-focus"
                    >
                      <option></option>
                      {alphaList.map((id) => (
                        <option
                          key={`otherLiability.insurer2.${id}`}
                          value={id}
                        >
                          {id}
                        </option>
                      ))}
                    </Field>
                  </div>
                  <div className="mb-4">
                    <label className="label-class">
                      Other Liability (2) name
                    </label>
                    <Field
                      type="text"
                      name="otherLiability.insuranceTypes.custom2"
                      className="field-base field-focus"
                    />
                    <ErrorMessage
                      name="otherLiability.insuranceTypes.custom2"
                      component="div"
                      className="error-text"
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FieldArray
                    name="otherLiability.policies"
                    render={(arrayHelpers) => (
                      <>
                        {values.otherLiability.policies &&
                        values.otherLiability.policies.length ? (
                          values.otherLiability.policies.map(
                            (policy, index) => (
                              <div key={index}>
                                <div className="flex gap-2 mb-2">
                                  <div>
                                    <Field
                                      name={`otherLiability.policies[${index}].no`}
                                      placeholder="Policy number"
                                      className="field-base field-focus"
                                    />
                                    <ErrorMessage
                                      name={`otherLiability.policies.${index}.no`}
                                      component="div"
                                      className="error-text"
                                    />
                                  </div>
                                  <div>
                                    <Datetime
                                      inputProps={{
                                        name: `otherLiability.policies.${index}.effective`,
                                        id: `otherLiabilityPolicies${index}Effective`,
                                        autoComplete: "off",
                                        placeholder: "Effective MM/DD/YYYY",
                                      }}
                                      value={
                                        policy.effective &&
                                        moment(policy.effective).format(
                                          "MM/DD/YYYY"
                                        )
                                      }
                                      dateFormat="MM/DD/YYYY"
                                      timeFormat={false}
                                      closeOnSelect={true}
                                      onChange={(time) => {
                                        setFieldValue(
                                          `otherLiability.policies.${index}.effective`,
                                          time ? time : null
                                        );
                                      }}
                                      className="field-base field-focus"
                                    />
                                    <ErrorMessage
                                      name={`otherLiability.policies.${index}.effective`}
                                      component="div"
                                      className="error-text"
                                    />
                                  </div>
                                  <div>
                                    <Datetime
                                      inputProps={{
                                        name: `otherLiability.policies.${index}.expiry`,
                                        id: `otherLiabilityPolicies${index}Expiry`,
                                        autoComplete: "off",
                                        placeholder: "Expiry MM/DD/YYYY",
                                      }}
                                      value={
                                        policy.expiry &&
                                        moment(policy.expiry).format(
                                          "MM/DD/YYYY"
                                        )
                                      }
                                      dateFormat="MM/DD/YYYY"
                                      timeFormat={false}
                                      closeOnSelect={true}
                                      onChange={(time) => {
                                        setFieldValue(
                                          `otherLiability.policies.${index}.expiry`,
                                          time ? time : null
                                        );
                                      }}
                                      className="field-base field-focus"
                                    />
                                    <ErrorMessage
                                      name={`otherLiability.policies.${index}.expiry`}
                                      component="div"
                                      className="error-text"
                                    />
                                  </div>
                                  <div className="col-md-3">
                                    <IconButton
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      <RemoveCircleIcon />
                                    </IconButton>{" "}
                                    <IconButton
                                      onClick={() =>
                                        arrayHelpers.push({
                                          no: "",
                                          effective: "",
                                          expiry: "",
                                        })
                                      }
                                    >
                                      <AddCircleIcon />
                                    </IconButton>
                                  </div>
                                </div>
                              </div>
                            )
                          )
                        ) : (
                          <Button
                            variant="outlined"
                            size="small"
                            color="primary"
                            onClick={() =>
                              arrayHelpers.push({
                                no: "",
                                effective: null,
                                expiry: null,
                              })
                            }
                          >
                            Add policy
                          </Button>
                        )}
                      </>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <div className="mb-4">
                    <label className="label-class">Limit</label>
                    <Field
                      type="number"
                      name="otherLiability.limits.limit"
                      className="field-base field-focus"
                    />
                    <ErrorMessage
                      name="otherLiability.limits.limit"
                      component="div"
                      className="error-text"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="label-class">Deductible</label>
                    <Field
                      type="number"
                      name="otherLiability.limits.deductible"
                      className="field-base field-focus"
                    />
                    <ErrorMessage
                      name="otherLiability.limits.deductible"
                      component="div"
                      className="error-text"
                    />
                  </div>

                  <div className="mb-4">
                    <label className="label-class">Limit (2)</label>
                    <Field
                      type="number"
                      name="otherLiability.limits.limit2"
                      className="field-base field-focus"
                    />
                    <ErrorMessage
                      name="otherLiability.limits.limit2"
                      component="div"
                      className="error-text"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="label-class">Deductible (2)</label>
                    <Field
                      type="number"
                      name="otherLiability.limits.deductible2"
                      className="field-base field-focus"
                    />
                    <ErrorMessage
                      name="otherLiability.limits.deductible2"
                      component="div"
                      className="error-text"
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
            {/* otherLiability */}

            <div className="mb-4">
              <label htmlFor="description" className="label-class">
                Description of Operations/Locations/Vehicles (Attach ACORD 101,
                Additional Remarks Schedule, If more space is required)
              </label>
              <Field
                name="description"
                component="textarea"
                className={`field-base field-focus ${
                  touched.description && errors.description ? "field-error" : ""
                }`}
              />
              <ErrorMessage
                name="description"
                component="div"
                className="error-text"
              />
            </div>

            <div className="mb-4">
              <div className="form-check">
                <CheckboxBoolean
                  name="isCustomizable"
                  value={false}
                  className="form-check-input"
                  id="isCustomizable"
                />
                <label className="label-inline-class" htmlFor="isCustomizable">
                  Make pdf certificate editable (users with link shall be able
                  to change the date and certificate holders)
                </label>
              </div>
            </div>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={
                isSubmitting ? "btn btn-primary loading" : "btn btn-primary"
              }
              disabled={isSubmitting}
            >
              {isSubmitting ? "Saving" : "Save"}
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default CertificateManage;
