import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Drawer } from "@mui/material";
import { Link } from "react-router";
import GroupIcon from "@mui/icons-material/Group";
import SecurityIcon from "@mui/icons-material/Security";

const NavBar = ({ openMobile, onMobileClose, ...rest }) => {
  const navBarContent = (
    <div className="p-4">
      <ul>
        <li>
          <Button
            startIcon={<SecurityIcon />}
            component={Link}
            to="/dashboard/insurers"
          >
            Insurers
          </Button>
        </li>
        <li>
          <Button
            startIcon={<GroupIcon />}
            component={Link}
            to="/dashboard/contacts"
          >
            Contacts
          </Button>
        </li>
      </ul>
    </div>
  );

  useEffect(() => {
    if (openMobile) {
      onMobileClose && onMobileClose();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openMobile]);

  return (
    <>
      <div className="lg:hidden z-30 w-60 min-w-60 flex-none">
        <Drawer
          anchor="left"
          variant="temporary"
          onClose={onMobileClose}
          open={openMobile}
        >
          <div {...rest} className="h-full overflow-y-auto">
            {navBarContent}
          </div>
        </Drawer>
      </div>
      <div className="hidden lg:block z-30 w-60 min-w-60 flex-none">
        <div className="h-full overflow-y-auto bg-gray-50 mt-16">
          {navBarContent}
        </div>
      </div>
    </>
  );
};

NavBar.propTypes = {
  className: PropTypes.string,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
