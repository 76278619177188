import React, { useEffect, useState } from "react";
import { Outlet, useNavigate, useLocation } from "react-router";
import {
  setToken,
  setNavigator,
  setupAxiosInterceptors,
} from "../../api/axios";
import { useDispatch, useSelector } from "react-redux";
import { loginUserOffline, setInit } from "../../features/app/appSlice";

/*
 * This component is a wrapper for application level configuration
 * We will use this to initialize the Axios Library;
 * */
const Root = () => {
  let location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { status, token } = useSelector((state) => state.app);
  const [navigateInit, setNavigateInit] = useState(false);

  useEffect(() => {
    setupAxiosInterceptors();
    setNavigator(navigate);
    setNavigateInit(true);
  }, [navigate]);

  useEffect(() => {
    if (status === "idle" && !token) {
      // check if we can find it offline
      const localToken = localStorage.getItem("app") || null;
      dispatch(loginUserOffline(localToken));
    }

    if (status === "succeeded") {
      if (token) {
        setToken(token);
        dispatch(setInit());

        if (location.pathname === "/") {
          navigate("/dashboard");
        }
      } else {
        if (location.pathname === "/") {
          navigate("/login");
        }
      }
    }
  }, [status, token]);

  return (
    <>
      {navigateInit ? (
        <Outlet />
      ) : (
        <div className="p-4">Initializing navigator...</div>
      )}
    </>
  );
};

export default Root;
