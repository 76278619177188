import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as userApi from "../../api/usersApi";
import { jwtDecode } from "jwt-decode";

export const loginUser = createAsyncThunk(
  "users/loginUser",
  async (data) => await userApi.loginUser(data)
);

const initialState = {
  user: null,
  status: "idle",
  error: null,
  isInit: false,
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setInit: (state) => {
      state.isInit = true;
    },
    logoutUser: () => {
      localStorage.removeItem("app");
      return initialState;
    },
    loginUserOffline: (state, action) => {
      const token = action.payload;
      state.status = "succeeded";
      if (token) {
        state.user = jwtDecode(token);
        state.token = token;
        // also save in localStorage for later use;
        localStorage.setItem("app", token);
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(loginUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        if (action.payload?.token) {
          const { token } = action.payload;
          const decodedToken = jwtDecode(token);
          state.status = "succeeded";
          state.user = decodedToken;
          state.token = token;
          // also save in localStorage for later use;
          localStorage.setItem("app", token);
        }
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action?.error?.message;
      });
  },
});

export default appSlice.reducer;

export const { setInit, setInitFailed, loginUserOffline, logoutUser } =
  appSlice.actions;

// Export default selectors
export const appUser = (state) => state.app;
