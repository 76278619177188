import axios from "./axios";

const baseUrl = "/contacts";

export const getContacts = () => axios.get(baseUrl);

export const postContact = (data) => axios.post(baseUrl, data);

export const updateContact = ({ id, ...data }) =>
  axios.put(`${baseUrl}/${id}`, data);
