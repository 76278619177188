import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router";
import { Formik, Form } from "formik";
import {
  AppBar,
  Button,
  Hidden,
  Toolbar,
  IconButton,
  Input,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import InputIcon from "@mui/icons-material/Input";
import MenuIcon from "@mui/icons-material/Menu";
import { useDispatch } from "react-redux";
import { logoutUser } from "features/app/appSlice";

const TopBar = ({ onOpenNavBarMobile }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.app);

  return (
    <AppBar className="shadow-none">
      <Toolbar>
        <div className="lg:hidden">
          <IconButton color="inherit" onClick={onOpenNavBarMobile}>
            <MenuIcon />
          </IconButton>
        </div>
        <Link to="/" className="text-white">
          <span className="text-lg font-bold">Scottroad Ins.</span>
          <span className="ml-2 pl-2 border-l text-sm">{user?.email}</span>
        </Link>
        <div className="flex-grow" />
        <Hidden smDown>
          <Formik
            initialValues={{ search: "" }}
            onSubmit={(values) => {
              navigate(`/dashboard/contacts?search=${values.search}`);
            }}
          >
            {({ handleChange, handleBlur, values }) => (
              <Form>
                <div className="bg-white/10 rounded-[4px] flex basis-[300px] h-[36px] px-2 items-center">
                  <SearchIcon />
                  <Input
                    sx={{
                      "& .MuiInputBase-input": {
                        color: "white",
                      },
                      "& .MuiInputBase-input::placeholder": {
                        color: "white",
                      },
                    }}
                    disableUnderline
                    name="search"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Search contacts"
                    value={values.name}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </Hidden>
        <Button
          color="inherit"
          onClick={() => {
            dispatch(logoutUser());
            navigate("/logout");
          }}
        >
          <InputIcon />
          Log out
        </Button>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onOpenNavBarMobile: PropTypes.func.isRequired,
};

export default TopBar;
