import { configureStore } from "@reduxjs/toolkit";
import appReducer from "./app/appSlice";
import contactsReducer from "./contacts/contactsSlice";
import insurersReducer from "./insurers/insurersSlice";
import certificatesReducer from "./certificates/certificatesSlice";

const store = configureStore({
  reducer: {
    app: appReducer,
    insurers: insurersReducer,
    contacts: contactsReducer,
    certificates: certificatesReducer,
  },
});

export default store;
