import React, { useState } from "react";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { Auth } from "layouts";
import { useNavigate } from "react-router";
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { loginUser } from "../../features/app/appSlice";

const LoginSchema = Yup.object().shape({
  email: Yup.string().email().required("email is required"),
  password: Yup.string().required("Password is required"),
});

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Auth>
      <div className="w-full max-w-sm">
        <h1 className="text-4xl font-bold mb-6">LogIn</h1>
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          validationSchema={LoginSchema}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            dispatch(loginUser(values))
              .unwrap()
              .then(() => {
                navigate("/dashboard/contacts");
              })
              .catch((err) => {
                console.error(err);
                toast.error("something went wrong");
              })
              .finally(() => setSubmitting(false));
          }}
        >
          {({
            isSubmitting,
            errors,
            values,
            handleChange,
            handleBlur,
            touched,
          }) => (
            <Form>
              <Field type="hidden" name="plan" className="form-control" />

              <div className="mb-4">
                <TextField
                  fullWidth
                  error={errors.email && touched.email}
                  helperText={errors.email && touched.email && errors.email}
                  label="CertificateEmail"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  variant="outlined"
                />
              </div>

              <div className="mb-4">
                <FormControl
                  fullWidth
                  error={errors.password && touched.password}
                  variant="outlined"
                >
                  <InputLabel>Password</InputLabel>
                  <OutlinedInput
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type={showPassword ? "text" : "password"}
                    value={values.password}
                    variant="outlined"
                  />
                </FormControl>
              </div>

              <Button
                color="primary"
                disabled={isSubmitting}
                disableElevation
                type="submit"
                variant="contained"
              >
                {isSubmitting ? "loading.." : "Log In"}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </Auth>
  );
};

export default Login;
