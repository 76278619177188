import axios from "axios";
import { toast } from "react-toastify";

let token = null;
let navigate = null;

// Function to set the token dynamically
export const setToken = (newToken) => {
  token = newToken;
};

// Function to set the navigator dynamically
export const setNavigator = (nav) => {
  navigate = nav;
};

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "content-type": "application/json",
  },
});

export const setupAxiosInterceptors = () => {
  // REQUEST
  axiosInstance.interceptors.request.use(
    (request) => {
      if (token) {
        request.headers.Authorization = `Bearer ${token}`;
      }

      return request;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // RESPONSE
  axiosInstance.interceptors.response.use(
    (response) => {
      if (response.data) {
        return response.data;
      } else {
        return response;
      }
    },
    (error) => {
      if (error.response) {
        /*
         * The request was made and the server responded with a status code
         * that falls out of the range of 2xx
         *
         * We have display the error in the interceptor itself as Redux unwrap will strip off the unserializable properties
         * and response properties are unserializable, will get striped out.
         * */

        if (error.response.status === 401 || error.response.status === 403) {
          if (navigate) {
            navigate(`/unauthorized?statusCode=${error.response.status}`);
          } else {
            console.error(error);
          }
        } else if (error.response.status === 500) {
          toast.error("There is a critical internal server error", {
            position: "top-center",
            autoClose: false,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
          });
        } else if (error.response.status === 400) {
          // bad request;
          toast.error(
            `Error: ${
              error.response?.data?.validation?.body?.message
                ? error.response.data.validation.body.message
                : " bad request"
            }`
          );
        }

        // return all errors; let the components take care of them
      } else if (error.request) {
        // The request was made but no response was received
        toast.error(error.message || "Request failed, received no response");
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error(error);
        toast.error(error.message || "Request failed");
      }

      return Promise.reject(error);
    }
  );
};

export default axiosInstance;
