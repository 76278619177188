import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import * as insurerApi from "api/insurersApi";

const insurersAdaptor = createEntityAdapter({
  selectId: (insurer) => insurer._id,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

export const getInsurers = createAsyncThunk(
  "insurers/getInsurers",
  async () => await insurerApi.getInsurers()
);

export const postInsurer = createAsyncThunk(
  "insurers/postInsurer",
  async (data) => await insurerApi.postInsurer(data)
);

export const updateInsurer = createAsyncThunk(
  "insurers/updateInsurer",
  async (data) => await insurerApi.updateInsurer(data)
);

const initialState = insurersAdaptor.getInitialState({
  status: "idle",
  error: null,
});

const insurersSlice = createSlice({
  name: "insurers",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getInsurers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getInsurers.fulfilled, (state, action) => {
        if (action.payload) {
          state.status = "succeeded";
          insurersAdaptor.setAll(state, action.payload);
        }
      })
      .addCase(getInsurers.rejected, (state, action) => {
        state.status = "failed";
        state.error = action?.error?.message;
      })
      .addCase(postInsurer.fulfilled, (state, action) => {
        state.status = "succeeded";
        insurersAdaptor.addOne(state, action.payload);
      })
      .addCase(updateInsurer.fulfilled, (state, action) => {
        const { _id, ...changes } = action.payload;
        state.status = "succeeded";
        insurersAdaptor.updateOne(state, {
          id: _id,
          changes,
        });
      });
  },
});

export default insurersSlice.reducer;

// Export default selectors
export const { selectAll: selectAllInsurers, selectById: selectInsurerById } =
  insurersAdaptor.getSelectors((state) => state.insurers);
