import React, { useEffect } from "react";
import { RouterLink } from "components";
import { Button, Typography } from "@mui/material";
import Auth from "../../layouts/Auth";

const Logout = () => {
  useEffect(() => {
    localStorage.removeItem("user");
  }, []);

  return (
    <Auth>
      <div className="p-4">
        <Typography align="center" gutterBottom variant="h2">
          Logged out
        </Typography>
        <Typography align="center" gutterBottom>
          You have successfully logged out of the dashboard.
        </Typography>
        <div className="text-center p-4">
          <Button
            color="primary"
            component={RouterLink}
            disableElevation
            to="/login"
            variant="contained"
          >
            Log In Again
          </Button>
        </div>
      </div>
    </Auth>
  );
};

export default Logout;
