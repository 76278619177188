import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getInsurers } from "features/insurers/insurersSlice";

const useInsurers = () => {
  const dispatch = useDispatch();
  const { status, error } = useSelector((state) => state.insurers);

  useEffect(() => {
    if (status === "idle") {
      dispatch(getInsurers());
    }
  }, [dispatch, status]);

  return {
    status,
    error,
  };
};

export default useInsurers;
