import React, { useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "components";
import { useContacts } from "hooks";
import { selectContactById } from "features/contacts/contactsSlice";
import {
  selectFetchedContacts,
  selectAllCertificates,
  getCertificatesByContactId,
} from "features/certificates/certificatesSlice";
import { Button, IconButton } from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import EmailIcon from "@mui/icons-material/Email";
import { format, parseISO } from "date-fns";
import { getCertificatePdf } from "../../api/certificatesApi";

const Certificates = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { status: statusContacts, error: errorContacts } = useContacts();
  const { contactId } = useParams();
  const contact = useSelector((state) => selectContactById(state, contactId));
  const { status: statusCertificates, error: errorCertificates } = useSelector(
    (state) => state.certificates
  );
  const fetchedContacts = useSelector(selectFetchedContacts);
  const certificates = useSelector(selectAllCertificates);

  useEffect(() => {
    if (!fetchedContacts.includes(contactId)) {
      dispatch(getCertificatesByContactId(contactId));
    }
  }, [dispatch, contactId, fetchedContacts.length]);

  if (statusContacts === "loading") return <Spinner />;
  if (statusContacts === "failed")
    return <div className="text-red-600">Error: {errorContacts}</div>;

  if (statusCertificates === "loading") return <Spinner />;
  if (statusCertificates === "failed")
    return <div className="text-red-600">Error: {errorCertificates}</div>;

  return (
    <>
      <div className="mb-2 sm:flex justify-between items-center">
        <div>
          <h1 className="text-4xl font-bold">Certificates</h1>
          <h2>
            <span className="uppercase text-gray-600 font-medium text-lg mr-2">
              {contact?.name}
            </span>
          </h2>
        </div>
        <div>
          <Button
            component={Link}
            to="./add"
            variant="contained"
            color="primary"
          >
            Add Certificate
          </Button>
        </div>
      </div>
      <div className="mb-6">
        <Button
          variant="outlined"
          color="primary"
          size="small"
          component={Link}
          to={`../update`}
          className="text-blue-600 hover:text-black"
        >
          Update Contact
        </Button>
      </div>
      <ul className="border-t">
        {certificates
          .filter(
            (certificate) =>
              certificate?.insured?._id === contactId ||
              certificate?.holder?._id === contactId
          )
          .map((certificate) => {
            const { _id, insured, holder, createdOn } = certificate;
            return (
              <li key={_id} className="border-b">
                <Link
                  to={`./${_id}`}
                  className="block px-2 py-3 hover:bg-amber-50"
                >
                  <div className="flex justify-between items-center">
                    <div>
                      <div>
                        <div className="mr-2 text-gray-600 text-sm">
                          {format(parseISO(createdOn), "MMM do, yyyy")}
                        </div>
                        <div className="text-blue-600 hover:text-black">
                          <span className="font-medium">{insured?.name}</span>/
                          {holder?.name}
                        </div>
                      </div>
                    </div>
                    <div>
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          getCertificatePdf(_id)
                            .then((data) => {
                              // Create a URL for the Blob
                              const blobURL = URL.createObjectURL(
                                new Blob([data], { type: "application/pdf" })
                              );

                              // Create a link element
                              const link = document.createElement("a");
                              link.href = blobURL;
                              link.download = `${_id}.pdf`; // Set the desired file name
                              document.body.appendChild(link);

                              // Trigger the download
                              link.click();

                              // Cleanup
                              document.body.removeChild(link);
                              URL.revokeObjectURL(blobURL);
                            })
                            .catch((err) => console.error(err));
                        }}
                      >
                        <PictureAsPdfIcon />
                      </IconButton>
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          navigate(`./${_id}/email`);
                        }}
                      >
                        <EmailIcon />
                      </IconButton>
                    </div>
                  </div>
                </Link>
              </li>
            );
          })}
      </ul>
    </>
  );
};

export default Certificates;
