import React, { useEffect } from "react";
import { Spinner } from "components";
import { Link } from "react-router";
import { useInsurers } from "hooks";
import { useSelector } from "react-redux";
import { selectAllInsurers } from "features/insurers/insurersSlice";
import { Button } from "@mui/material";

const Insurers = () => {
  const { status, error } = useInsurers();
  const insurers = useSelector(selectAllInsurers);

  useEffect(() => {}, []);

  if (status === "loading") return <Spinner />;
  if (status === "failed")
    return <div className="text-red-600">Error: {error}</div>;

  return (
    <>
      <div className="flex justify-between">
        <div>
          <h1 className="text-4xl font-bold">Insurers</h1>
          {insurers.length && (
            <p className="my-4 text-gray-600">
              found <span className="font-bold">{insurers.length}</span>{" "}
              insurers
            </p>
          )}
        </div>
        {/* left */}
        <div className="text-right">
          <Button
            component={Link}
            to="./add"
            variant="contained"
            color="primary"
          >
            Add Insurer
          </Button>
        </div>
      </div>
      <ul className="border-t">
        {insurers.map(({ _id, name, phone, email }) => (
          <li className="border-b" key={_id}>
            <Link
              to={`${_id}/update`}
              className="block px-2 py-3 hover:bg-amber-50 text-blue-600 hover:text-black"
            >
              <h5 className="font-medium">{name}</h5>
            </Link>
          </li>
        ))}
      </ul>
    </>
  );
};

export default Insurers;
