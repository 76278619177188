import { createSelector } from "@reduxjs/toolkit";

// Base selectors
const selectInsurers = (state) => state.insurers.entities;
const selectCertificates = (state) => state.certificates.entities;

// Selector to get all contacts for a given certificate ID
const selectCertificateInsurers = (certificateId) =>
  createSelector(
    [selectCertificates, selectInsurers],
    (certificates, insurers) => {
      const certificate = certificates[certificateId];
      if (!certificate) return [];
      return certificate.insurers.map((insurersId) => insurers[insurersId]);
    }
  );
export default selectCertificateInsurers;
