import React from "react";
import { useRouteError } from "react-router";

const ErrorPage = () => {
  const error = useRouteError();
  console.error(error);

  return (
    <div className="text-center p-5">
      <h1 className="text-2xl font-bold mb-4">
        Oops! {error.statusText || error.message || ""}
      </h1>
      <p className="text-gray-600">Sorry, an unexpected error has occurred.</p>
    </div>
  );
};

export default ErrorPage;
